/* eslint-disable @typescript-eslint/no-unused-vars */
import * as enums from "vars/enums";
import * as variables from "vars/variables";
import ProgressionBar from "components/ProgressionBar";
import rouleau_top from "images/rouleau_top.svg";
import rouleau_bottom from "images/rouleau_bottom.svg";
import { useGameInterfaceStore } from 'stores/useGameInterfaceStore';
 
function Donation() {
	const [
		configPage,
		getCagnotte,
		getRegion,
		getUserScore,
	] = useGameInterfaceStore((state) => [
		
		state.configPage,
		state.getCagnotte,
		state.getRegion,
		state.getUserScore,
	]);


	const currScore = (getUserScore());
	const nbToPointsReach = (enums.NB_POINT_TO_REACH);
	const nbToEurosReach = enums.NB_EUROS_TO_REACH;
	const currCagnotte = getCagnotte();
	const isWinner = currScore >= nbToPointsReach;
	const progress = parseInt(((currCagnotte / nbToEurosReach) * 100).toString());
	const currency = "€";
	
	const hasCagnotte = currCagnotte > 0;

	const regionCode = getRegion();
	let regionName = "";
	let showCagnotte = true;
	switch (regionCode) {
		case "MQ":
			regionName = "Martinique";
			break;
		case "GP":
			regionName = "Guadeloupe";
			break;
		case "RE":
			regionName = "Réunion";
			break;
		case "GY":
			regionName = "Guyane";
			break;

		default:
			showCagnotte = false;
			break;
	}
	if (!showCagnotte) return "";
	return (
		<div className="bloc_container">
			<div className="block_top hoye_top">
				<img src={rouleau_top} alt="" />
			</div>
			<div className="block_middle hoye_middle">
				<div>
					{configPage === 1 ? (
						<>
							<hr />
							<div className="sub_title">SOUTENEZ UNE ASSOCIATION <br/>LOCALE !</div>
							{/* <div className="sub_sub_title">a une association </div> */}

							<hr />
							<p className="cagnotte_lbl novaStyleBold">
								Jouez, triez et remportez le maximum de points.
								{/* <span className="nbpoints">{nbToPointsReach}pts</span> 								
								<span className="amount">{variables.WINNER_PRICE}€</span>*/}								
							</p>
							<p className="cagnotte_lbl novaStyleBold">
								Vos points seront convertis en euros pour soutenir une association de votre région.
								
							</p>
							{/* {hasCagnotte ? (
								<>
									<p className="cagnotte_lbl novaStyleBold">
										Cagnotte de {regionName} :
									</p>
									<ProgressionBar
										hasStars={false}
										totalToReach={nbToEurosReach}
										nbPoints={currCagnotte}
										progress={progress}
										currency={currency} 
																			/>
								</>
							) : (
								""
							)} */}
						</>
					) : (
						<>
							<hr />
							<div className="sub_title">CAGNOTTE </div>
							<div className="sub_sub_title">{regionName} </div>
							<hr />
							<p>
								Si vous faites plus de <b>{nbToPointsReach}pts</b>,
								<br />
								<b>{variables.WINNER_PRICE}€</b> seront ajoutés à la cagnotte.
							</p>
							{hasCagnotte ? (
								<p>
									<ProgressionBar
										hasStars={false}
										totalToReach={nbToEurosReach}
										nbPoints={currCagnotte}
										progress={progress}
										currency={currency}
									/>
								</p>
							) : (
								""
							)}
							{isWinner ? (
								<p>
									Bravo, vous avez fait plus de <b>{nbToPointsReach}pts</b>,
									5€ seront reversés à l'association.
								</p>
							) : (
								<p>
									Hélas, cette fois vous n'avez pas atteint les{" "}
									<b>{nbToPointsReach}pts</b>, mais retentez votre chance pour
									l'association!
								</p>
							)}
							<br />
						</>
					)}
				</div>
			</div>
			<div className="block_bottom hoye_bottom">
				<img src={rouleau_bottom} alt="" />
			</div>
		</div>
	);
}

export default Donation