import styled from "styled-components";

export const StyledGoodPoint = styled.div`
	width: 50px;
	position: absolute;
`;

export const StyledGoodPointImg = styled.img`
	width: 50px;
`;
