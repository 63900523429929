/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import CryptoJS from "crypto-js";
import axios from "axios";
import * as enums from "vars/enums";
import * as variables from "vars/variables";

let prox = "";
prox = "";
//prox = "https://cors.bridged.cc/";
//prox ="https://cors-anywhere.herokuapp.com/";
const gameVersion = "1.0.1.1";
const jetribienString = "jetriebien_" + window.location.host;
const scrt = (process.env.VITE_AUTH_TO_STATS ?? "undefined").substring(0, 5);

const ipsnippet: any = {};
let _cache: any = {};
export const getGoodStrorageContent = () => {
	const localStore = localStorage.getItem(jetribienString);

	let jetriebien = null;

	try {
		const decrtd = decrypt(localStore ?? "");
		jetriebien = decrtd.data;

		//console.log(jetriebien)
	} catch (error) {
		//todo catch error and report
	}

	let jsonValue;
	if (jetriebien) {
		try {
			jsonValue = jetriebien;
		} catch (error) {
			//todo
		}
	} else {
		const enc = encrypt(newGame);
		localStorage.setItem(jetribienString, enc ?? "");
		jsonValue = newGame;
	}

	return jsonValue;
};

export const newGame = {
	version: gameVersion,
	nbLife: variables.INIT_NBLIFE,
	trashType: 0,
};

export const setStorage = (value: any) => {
	const date = new Date();
	value.update =
		date.getFullYear() + "_" + date.getMonth() + "_" + date.getDate();

	_cache = value;
	const enc = encrypt(value);
	localStorage.setItem(jetribienString, enc!);
};

export const getJeTrieBien = () => {
	if (!_cache || _cache.vesion !== gameVersion) {
		const jsonValue = getGoodStrorageContent();
		if (!jsonValue || !jsonValue.version || jsonValue.version !== gameVersion) {
			setVersion();
		}
		_cache = jsonValue;
		return jsonValue;
	} else {
		return _cache;
	}
};

export const setVersion = () => {
	const jetriebien = getGoodStrorageContent();
	setStorage({
		...jetriebien,
		...newGame,
		instructionPage: enums.INTRUCTION_PAGE_INTRO_1,
	});
};

export const encrypt = (data: any) => {
	if (!data || data === "") return null;
	const encryptText = CryptoJS.AES.encrypt(
		JSON.stringify({ data }),
		scrt
	).toString();
	return encryptText ?? "";
};

export const decrypt = (data: string) => {
	if (!data || data === "") return null;
	const decryptedBytes = CryptoJS.AES.decrypt(data, scrt);
	const decStr = decryptedBytes.toString(CryptoJS.enc.Utf8);
	return JSON.parse(decStr);
};

export const DateDiff = {
	inMinutes: function (d1: Date, d2: Date) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt(((t2 - t1) / 1000).toString());
	},

	inHours: function (d1: Date, d2: Date) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt(((t2 - t1) / (3600 * 1000)).toString());
	},

	inDays: function (d1: Date, d2: Date) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt(((t2 - t1) / (24 * 3600 * 1000)).toString());
	},

	inWeeks: function (d1: Date, d2: Date) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt(((t2 - t1) / (24 * 3600 * 1000 * 7)).toString());
	},

	inMonths: function (d1: Date, d2: Date) {
		const d1Y = d1.getFullYear();
		const d2Y = d2.getFullYear();
		const d1M = d1.getMonth();
		const d2M = d2.getMonth();

		return d2M + 12 * d2Y - (d1M + 12 * d1Y);
	},

	inYears: function (d1: Date, d2: Date) {
		return d2.getFullYear() - d1.getFullYear();
	},
};

export const savePlaceData = (data: any) => {
	const jetriebien = getJeTrieBien();
	if (data) {
		const ecrpt = data;
		setStorage({
			...jetriebien,
			placeData: {
				debut: new Date(),
				value: ecrpt,
			},
		});
	} else {
		setStorage({
			...jetriebien,
			placeData: jetriebien.placeData ?? null,
		});
	}
};

export const loadPlaceData = () => {
	const jetriebien = getJeTrieBien();
	//let value = null;
	if (jetriebien.placeData && jetriebien.placeData !== "") {
		const dt1 = new Date(jetriebien.placeData.debut);
		const dt2 = new Date();
		const diffBet = DateDiff.inMinutes(dt1, dt2);
		/*if (diffBet > 20 || !value) {
			savePlaceData(null);
			return null;
		}
		try {
			value = jetriebien.placeData.value;
		} catch (error) {
			//todo catch error and report
		}*/
		return jetriebien.placeData.value;
	}
	return null; //value;
};

export const getCampagneValuesFromUrl = (
	campagne: number,
	qparams: any,
	ipParams: any
) => {
	const goeS = {
		campagne: campagne,
		href: window.location.href,
		search: window.location.search,
		url: window.location.host,
		format: getMobileTypeName(),
		countryCode:
			ipParams.loc ?? ipParams.countryCode ?? ipParams.country ?? ipParams.cc,
		countryName: ipParams.countryName ?? ipParams.country ?? ipParams.region,
		count: ipParams.count,
		dmaCode: ipParams.dmaCode ?? ipParams.ip,
		score: ipParams.score,
		totalScore: ipParams.totalScore,
		timezone: ipParams.uag,
		areaCode: process.env.NODE_ENV,
		/*latitude: ipParams.loc.split(",")[0],
		longitude: ipParams.loc.split(",")[1],
		timezone: ipParams.timezone,
		city: ipParams.city,
		region: ipParams.region,*/
	};
	return goeS;
};

export const getMobileTypeName = () => {
	if (/iphone/i.test(navigator.userAgent.toLowerCase())) {
		return "iphone";
	} else if (/ipad/i.test(navigator.userAgent.toLowerCase())) {
		return "ipad";
	} else if (/ipod/i.test(navigator.userAgent.toLowerCase())) {
		return "ipod";
	} else if (/android/i.test(navigator.userAgent.toLowerCase())) {
		return "android";
	} else if (/blackberry/i.test(navigator.userAgent.toLowerCase())) {
		return "blackberry";
	} else if (/mini/i.test(navigator.userAgent.toLowerCase())) {
		return "mini";
	} else if (/windows\sce/i.test(navigator.userAgent.toLowerCase())) {
		return "windows";
	} else if (/palm/i.test(navigator.userAgent.toLowerCase())) {
		return "palm";
	} else if (/CrOS/i.test(navigator.userAgent.toLowerCase())) {
		return "Chromebook";
	} else if (/Macintosh/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Mac";
	} else if (/Linux/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Linux";
	} else if (/Xbox/i.test(navigator.userAgent.toLowerCase())) {
		return "Xbox";
	} else if (/Nintendo/i.test(navigator.userAgent.toLowerCase())) {
		return "Nintendo";
	} else if (/Windows NT/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Windows";
	} else if (/CrKey/i.test(navigator.userAgent.toLowerCase())) {
		return "Chromecast";
	} else if (/AppleTV/i.test(navigator.userAgent.toLowerCase())) {
		return "AppleTV";
	} else if (/PlayStation/i.test(navigator.userAgent.toLowerCase())) {
		return "PlayStation";
	} else if (
		/Googlebot/i.test(navigator.userAgent.toLowerCase()) ||
		/bingbot/i.test(navigator.userAgent.toLowerCase()) ||
		/Yahoo/i.test(navigator.userAgent.toLowerCase())
	) {
		return "Bot";
	}

	return "autre";
};

export const postStatData = (campagne: number, data: any) => {
	const queryParams = new URLSearchParams(window.location.search);
	const parsedData = getCampagneValuesFromUrl(campagne, queryParams, data);
	const db_entry = { data: encrypt(parsedData) };

	const requestOptions = {
		method: "POST",
		headers: {
			/*...fetchHeaders,*/
			"content-type": "application/json",
			Authorization: `Bearer ${process.env.VITE_AUTH_TO_STATS}`,
		},
	};
	axios
		.post(prox + process.env.VITE_STAT_API_URL, db_entry, requestOptions)
		.then((response: any) => {
			//todo do something
		})
		.catch((err: any) => {
			//todo catch error and report
		});
};
