/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import * as enums from "./vars/enums";
import * as variables from "./vars/variables";
import * as gameFunctions from "./vars/gameFunctions";

//import { Rnd } from "react-rnd";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import GameContainer from "./components/GameContainer";
import styled from "styled-components";
import powerUp from "./images/powerup.gif";
import { StyledTrashImage } from "./styles/StyledTrashImage";
import { Event, PageView } from "./hooks/useAnalyticsEventTracker";

import { useNavigate } from "react-router-dom";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
function GameStart() {
	const navigate = useNavigate();

	const [getNbLife, getGameMode, getIsSuperMode, setIsOnline, getData] =
		useGameInterfaceStore((state) => [
			state.getNbLife,
			state.getGameMode,
			state.getIsSuperMode,
			state.setIsOnline,
			state.getData,
		]);

	const switchTrash = () => {
		const isSuperMode = getIsSuperMode();

		if (!isSuperMode) {
			const typ =
				gameFunctions.getTrashType() === enums.TRASH_TYPE_GREEN
					? enums.TRASH_TYPE_YELLOW
					: enums.TRASH_TYPE_GREEN;
			gameFunctions.setTrashType(typ);

			const trash = document.getElementById("poubelle_item")!;
			const GMode = getGameMode();
			trash.setAttribute("src", gameFunctions.generateTrash(typ, GMode));
		}
		//this.setState({ trash_type: typ });
	};

	//const refreshInterface = () => {
	//	setRefreshInterface(true);
	//};
	const trash = gameFunctions.generateTrash(
		gameFunctions.getTrashType(),
		getGameMode()
	);

	// const StyledTrashZoneContainer = styled.div`
	// 	position: absolute;
	// 	cursor: pointer;
	// 	-webkit-user-select: none;
	// 	user-select: none;
	// 	text-transform: none;
	// 	text-rendering: auto;
	// 	text-decoration: none;
	// `;
	// const StyledTrashZone = styled.div`
	// 	//position: absolute;
	// 	cursor: pointer;
	// 	-webkit-user-select: none;
	// 	user-select: none;
	// 	text-transform: none;
	// 	text-rendering: auto;
	// 	text-decoration: none;
	// `;

	const StyledShining = styled.div`
		position: absolute;
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
		text-transform: none;
		text-rendering: auto;
		text-decoration: none;
	`;

	const setGA = () => {
		//Prod
		//ReactGA.initialize("G-JH4MFPFJJX");
		//Dev
		//ReactGA.initialize("G-BD9SYBSEKB");
		//ReactGA.pageview("Lunch Game");
		PageView();
		Event("Lunch Game", "Game lunched", "GAME_PAGE");
	};

	const pickerRef = useRef(null);
	const cellsRef = useRef(null);
	useLayoutEffect(() => {
		gsap.defaults({ ease: "none" });
		const ctx = gsap.context(() => {
			const picker = pickerRef.current;
			const cells = cellsRef.current;
			const proxy = document.querySelector("#poubelle");
			const trashZoneContainer = document.querySelector("#trashZoneContainer")!;
			const myWrapper = gsap.utils.wrap(0, 1);

			const baseTl = gsap.timeline({ paused: true });

			gsap.set(picker, {
				//width: wrapWidth - cellWidth,
			});
			// cells.forEach((cell, i) => {
			// 	initCell(cell, i);
			// });

			gsap.set(cells, { x: 0 });

			const tl = gsap.timeline({ repeat: 1 });

			const draggable = Draggable.create(proxy, {
				type: "x",
				cursor: "grab",
				trigger: picker,
				dragResistance: 0.01,
				dragClickables: true,
				//bounds: "#trashZoneContainer",
				bounds: {
					minX: trashZoneContainer.clientLeft,
					maxX: trashZoneContainer.getBoundingClientRect().width - 140,
				},
				//liveSnap: onLiveSnap,
				allowContextMenu: true,
				onDrag: function (e) {
					e.stopPropagation();
					e.preventDefault();
					const nbLife = getNbLife();
					gameFunctions.UpdateTrashPosition(
						parseInt(((nbLife / variables.INIT_NBLIFE) * 100).toString())
					);
				},
				onThrowComplete: () => {
					//console.log("onThrowComplete");
					// TODO: animation that injects selected card title
				},
			})[0];
		});
		return () => ctx.revert();
	}, []);

	useEffect(() => {
		navigate("/#LunchGame");
		setGA();
		try {
			const promesseA = new Promise(() => getData());
			promesseA.then((val) => {
				setIsOnline(1);
			});
		} catch (error) {
			setIsOnline(0);
		}
	}, []);

	/*  */
	return (
		<>
			<GameContainer
			//goToScore={goToScore}
			//	refreshInterface={refreshInterface}
			/>
			<StyledShining
				className={"powerUp"}
				id="powerUp"
				data-name="powerUp"
			>
				<StyledTrashImage
					id="powerUp_item"
					src={powerUp}
					alt=""
				/>
			</StyledShining>
			<div
				ref={pickerRef}
				className={"trashZoneContainer"}
				id="trashZoneContainer"
				data-name="trashZoneContainer"
			>
				<div
					ref={cellsRef}
					className={"poubelle  draggable "}
					id="poubelle"
					data-name="poubelle"
					onClick={() => {
						switchTrash();
					}}
				>
					<StyledTrashImage
						className={
							getGameMode() === enums.GAME_MODE_EASY ? "easy_mode" : "hard_mode"
						}
						id="poubelle_item"
						src={trash}
						data-trashtype={gameFunctions.getTrashType()}
						alt=""
						data-gameMode={getGameMode()}
						data-nbLife={getNbLife()}
						data-totalLife={variables.INIT_NBLIFE}
					/>
				</div>
			</div>
		</>
	);
}

export default GameStart;
