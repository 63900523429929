import React from "react";
import star from "../images/star.png";
import empty_star from "../images/empty_star.png";
import styled from "styled-components";
import { ProgressBarProps } from "vars/types";
function ProgressionBar(props: ProgressBarProps) {
	const hasStars: boolean = props.hasStars ? props.hasStars : true;
	const totalToReach: number = props.totalToReach;
	let nbPoints = props.nbPoints;
	const currency = props.currency
		? props.currency
		: "pt" + (nbPoints > 1 ? "s" : "");
	let percent = (props.progress);
	if (percent > 100) {
		percent = 100;
	}

	const StyledProgression = styled.div`
		width: ${percent}%;
	`;
	const nbUsers = props.nbUsers ? props.nbUsers : [];
	const nbDisabledUsers = props.nbDisabledUsers ? props.nbDisabledUsers : [];

	if (nbPoints > totalToReach) {
		nbPoints = totalToReach;
	}
	return (
		<>
			{hasStars ? (
				<div className="starsContainer">
					{nbUsers.map((user, index) => {
						return (
							<div
								className="star"
								key={index}
							>
								<img
									src={star}
									alt=""
								/>
							</div>
						);
					})}
					{nbDisabledUsers.map((user, index) => {
						return (
							<div
								className="star"
								key={index}
							>
								<img
									src={empty_star}
									alt=""
								/>
							</div>
						);
					})}
				</div>
			) : (
				""
			)}
			<div className="pBarContainer">
				<div className="pBar">
					<StyledProgression className="progression">
						<b>{nbPoints}</b>
						{currency}
					</StyledProgression>
					<div className="progressBorder"> </div>
				</div>
			</div>
		</>
	);
}

export default ProgressionBar;
