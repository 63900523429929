/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import * as enums from "../vars/enums";
import * as variables from "../vars/variables";
import floor from "../images/floor_bottom.png";
import j1 from "../images/j1.png";
import star from "../images/star.png";

//import { Rnd } from "react-rnd";
import Stage from "./Stage";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import * as gameFunctions from "vars/gameFunctions";

function GameContainer() {
	const [
		getNbLife,
		getGameMode,
		getIsSuperMode,
		setScore,
		setNbLife,
		getScore,
		getSelectedUser,
	] = useGameInterfaceStore((state) => [
		state.getNbLife,
		state.getGameMode,
		state.getIsSuperMode,
		state.setScore,
		state.setNbLife,
		state.getScore,
		state.getSelectedUser,
	]);

	const [stage, setStage] = useState(1);

	const [trashImg, settrashImg] = useState(j1);

	const [refreshInterface, setrefreshInterface] = useState(false);

	const setGameMode = (gameMode: number) => {
		
		const trash = document.getElementById("poubelle_item")!;
		trash.setAttribute("gameMode", gameMode.toString());
		trash.setAttribute(
			"src",
			gameFunctions.generateTrash(
				parseInt(trash.getAttribute("type")!),
				gameMode
			)
		);
		trash.classList.remove("easy_mode");
		trash.classList.remove("hard_mode");
		trash.classList.add(
			gameMode === enums.GAME_MODE_EASY ? "easy_mode" : "hard_mode"
		);
		setGameMode(gameMode);
		setrefreshInterface(true);
	};

	const addScore = (scoretype: number) => {
		const oldScore = getScore();
		let newScore = oldScore;
		switch (scoretype) {
			case enums.SCORE_TYPE_SUCCESS:
				newScore +=
					getGameMode() === enums.GAME_MODE_HARD
						? variables.SCORE_ADD_HARD * (getIsSuperMode() ? 2 : 1)
						: variables.SCORE_ADD_NORMAL * (getIsSuperMode() ? 2 : 1);
				break;
			case enums.SCORE_TYPE_LOOSE:
				//	newScore -=
				//getGameMode() == enums.GAME_MODE_HARD
				//				? variables.SCORE_ADD_HARD
				//			: variables.SCORE_ADD_NORMAL;

				break;
			case enums.SCORE_TYPE_SUPERMODE:
				//newScore += variables.SCORE_ADD_SUPERMODE;
				break;
			default:
				break;
		}

		const stage = Math.round(newScore / variables.SCORE_CHECKPOINT) + 1;
		setScore(newScore);
		//const scoreText = document.querySelector("#scoreText")!;
		const scoreText = document.getElementById("scoreText")!;
		if (scoreText) {
			// scoreText.appendChild(document.createTextNode(newScore.toString()));
			//scoreText?.textContent = "";
			if (scoreText?.firstChild) {
				scoreText?.removeChild(scoreText?.firstChild);
			}
			//scoreText?.removeChild(scoreText?.children.item[0]);
			scoreText.append(newScore.toString());
			//scoreText?.innerHTML = ""//newScore.toString();
		}
		setStage(stage);
	};

	const addLife = (index: number) => {
		let nbLife = getNbLife() + index;
		if (nbLife < -1) {
			nbLife = 0;
		} else if (nbLife > variables.INIT_NBLIFE) {
			nbLife = variables.INIT_NBLIFE;
		}
		//console.log("addLife:" + nbLife);

		setNbLife(nbLife);

		//	let percent = parseInt((nbLife / (variables.INIT_NBLIFE + 1)) * 100);

		//	gameFunctions.UpdateTrashPosition(percent);
		//	const trashItem = document.getElementById("poubelle_item")!;

		const trash = document.getElementById("poubelle")!;
		const gameStartInterface = document.getElementById("gameStartInterface")!;
		trash.classList.remove("life_-1");
		gameStartInterface.classList.remove("life_-1");
		for (let index = 0; index <= 20; index++) {
			trash.classList.remove("life_" + index);
			gameStartInterface.classList.remove("life_" + index);
		}
		trash.classList.add("life_" + nbLife);
		gameStartInterface.classList.add("life_" + nbLife);
		setrefreshInterface(true);
	};

	const getSpeed = () => {
		const defaulSpeed = variables.DEFAULT_SPEED;
		const speed =
			defaulSpeed - Math.round(getScore() / variables.SCORE_CHECKPOINT) / 2;
		return speed > 0 ? speed : 0.5;
	};

	const nbLife = getNbLife();
	const gameMode = getGameMode();
	//const stage = this.state.stage;
	const score = getScore();
	const selectedUserId = getSelectedUser();
	const selectedUser = variables.users.find(
		(user) => user.userId === selectedUserId
	)!;
	/**/

	return (
		<div
			id="gameStartInterface"
			className={
				"gameStartInterface " +
				(nbLife >= 0 ? " life_" + nbLife + " " : "gameOver") +
				(gameMode === enums.GAME_MODE_HARD ? " mode_hard " : " mode_easy ")
			}
		>
			<Stage
				trashImg={trashImg}
				floor={floor}
				speed={getSpeed()}
				addLife={addLife}
				addScore={addScore}
			/>

			<div className="gameStartInterfaceTop">
				<div className="r_side">
					<div
						id="avatarIcon"
						className="avatarIcon"
					>
						<img
							src={selectedUser.src}
							alt="avatar"
						/>
						<div className="avatarText"> {selectedUser.name}</div>
					</div>
				</div>
				<div className="l_side">
					<div className="stageLbl">
						{
							//"Stage " + stage
							"Score : "
						}
					</div>
					<div className="stateScore">
						<span className="GillStyleBold">
							<span
								id="scoreText"
								className="scoreText"
							>
								{score}
							</span>
							<span className="pointText">pts</span>
						</span>
					</div>
				</div>
				<div
					id="superModeText"
					className="superModeText"
				>
					<div className="star">
						<img
							src={star}
							alt=""
						/>
					</div>
				</div>
				<div
					id="superModeBar"
					className="superModeBar"
				>
					<div className="pBarContainer">
						<div className="pBar">
							<div className="progression"></div>
							<div className="progressBorder"> </div>
						</div>
					</div>
				</div>
			</div>
			<div className="volet_G"></div>
			<div className="volet_D"></div>
		</div>
	);
}

export default GameContainer;
