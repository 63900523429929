/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import "./App.css";
import * as enums from "./vars/enums";
import { initGA, PageView } from "./hooks/useAnalyticsEventTracker";
import GameLoader from "./GameLoader";
//import DocumentMeta from "react-document-meta";
import { BrowserRouter as Router } from "react-router-dom";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";

const TRACKING_ID = "UA-XXXXX-X";
function App() {
	const [setMobileType, getMobileType] = useGameInterfaceStore((state) => [
		state.setMobileType,
		state.getMobileType,
	]);
	const hideAddressBar = () => {
		detectRotation();
		if (!window.location.hash) {
			try {
				if (document.height <= window.outerHeight + 10) {
					//if (document.height <= window.outerHeight + 10) {
					document.body.style.height = window.outerHeight + 50 + "px";
					setTimeout(function () {
						window.scrollTo(0, 1);
						//console.log("test");
					}, 50);
				} else {
					setTimeout(function () {
						window.scrollTo(0, 1);
						//console.log("test2");
					}, 0);
				}
			} catch (error) {
				//catch
			}
		}
	};

	const getCurrMobileType = () => {
		if (/iphone/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_IPHONE;
		} else if (/ipad/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_IPAD;
		} else if (/ipod/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_IPOD;
		} else if (/android/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_ANDROID;
		} else if (/blackberry/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_BBERRY;
		} else if (/mini/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_MINI;
		} else if (/windows\sce/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_WINDOWS;
		} else if (/palm/i.test(navigator.userAgent.toLowerCase())) {
			return enums.MOBILE_TYPE_PALM;
		}
		return enums.MOBILE_TYPE_OTHER;
	};

	const detectRotation = () => {
		const mobile =
			/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
				navigator.userAgent.toLowerCase()
			);

		if (mobile) {
			//alert("Visit this on a Computer for Better View");
		} else {
			//
		}

		const self = this; // Store `this` component outside the callback

		const phoneContainer = document.getElementById("phoneContainer")!;
		let isWellOriented = true;
		if (
			phoneContainer &&
			phoneContainer.getBoundingClientRect().width >=
				phoneContainer.getBoundingClientRect().height
		) {
			isWellOriented = false;
		}
		if (self && self.state && self.state.isWellOriented != isWellOriented) {
			self.setState({
				isWellOriented: isWellOriented,
			});
		}
	};

	useEffect(() => {
		initGA(TRACKING_ID);
		PageView();
		const mobileType = getMobileType();
		const currMobileType = getCurrMobileType();
		try {
			if(mobileType !== currMobileType)
			setMobileType(currMobileType);
		} catch (error) {
			//
		}

		detectRotation();
		window.addEventListener("load", hideAddressBar);
		window.addEventListener("orientationchange", hideAddressBar);
	}, []);
	return (
		<>
			<Router>
				<div
					id="app"
					className="App"
				>
					<GameLoader />
				</div>
			</Router>
		</>
	);
}

export default App;
