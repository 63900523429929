/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Spinner from "./Spiner";
import citeo_logo_black from "./images/citeo_logo_black.svg";
import GameSwitcher from "GameSwitcher";

import image_01 from "../dist/assets/appStoreButton-CG4xA2RW.png";
import image_02 from "../dist/assets/b_trash_green-t_lR5ghd.svg";
import image_03 from "../dist/assets/b_trash_yellow-TT1whHxx.svg";
import image_04 from "../dist/assets/bac_jaune-pCEzkeG7.png";
import image_05 from "../dist/assets/back_1-yWicmasK.svg";
import image_06 from "../dist/assets/background-f9lXHsMW.svg";
import image_07 from "../dist/assets/bad-VCkWR0-L.png";
import image_08 from "../dist/assets/BALLOON1-3NvhBNry.woff";
import image_09 from "../dist/assets/borne_verte-e4PYLR0u.png";
import image_10 from "../dist/assets/bravo-XWypxJMD.svg";
import image_11 from "../dist/assets/drag_green_trash-N9lnR4Su.svg";
import image_12 from "../dist/assets/floor_bottom-WjVsdAQH.png";
import image_13 from "../dist/assets/gameOver-oiU46K2y.png";
import image_14 from "../dist/assets/good-H1x9yWt1.png";
import image_15 from "../dist/assets/googlePlayButton-_hDc9SKx.png";
import image_16 from "../dist/assets/instruction_bg-7hysAmnO.png";
import image_17 from "../dist/assets/j1-UIqL8xUV.png";
import image_18 from "../dist/assets/j4-H7pex6pC.png";
import image_19 from "../dist/assets/j5-hMQ7T7-9.png";
import image_20 from "../dist/assets/j6-vSV8_WJ7.png";
import image_21 from "../dist/assets/j9-xMSTzs1U.png";
import image_22 from "../dist/assets/j10-5axKB0TD.png";
import image_23 from "../dist/assets/j11-bSWXd1C5.png";
import image_24 from "../dist/assets/j13-EuPEaQh4.png";
import image_25 from "../dist/assets/j14-woUvnzZv.png";
import image_26 from "../dist/assets/j15-8FkKW_9J.png";
import image_27 from "../dist/assets/j16-Ru56s2Xp.png";
import image_28 from "../dist/assets/j17-jrEkxKAB.png";
import image_29 from "../dist/assets/j20-w83-Rw8h.png";
import image_30 from "../dist/assets/j21-qDFNDRZX.png";
import image_31 from "../dist/assets/j22-J3EQixFn.png";
import image_32 from "../dist/assets/j24-AFvzuau7.png";
import image_33 from "../dist/assets/jaune_1-Nqjc3b0W.png";
import image_34 from "../dist/assets/jaune_2-aTyemeSC.png";
import image_35 from "../dist/assets/jaune_3-tR4KE2wC.png";
import image_36 from "../dist/assets/jaune_4-0GwBkr9K.png";
import image_37 from "../dist/assets/no_snooz-Kay-CYv-.gif";
import image_38 from "../dist/assets/papyrus_bottom-EMiQEc-u.svg";
import image_39 from "../dist/assets/papyrus_middle-UC8TImhR.svg";
import image_40 from "../dist/assets/papyrus_top-QcE7SJYP.svg";
import image_41 from "../dist/assets/passer_button-ikbQFfoj.png";
import image_42 from "../dist/assets/playground-Fctfglnk.png";
import image_43 from "../dist/assets/powerup-8LQeDWZQ.gif";
import image_44 from "../dist/assets/rouleau_top-G4K-65uf.svg";
//import image_45 from "../dist/assets/snooz-r77LKB70.gif";
import image_46 from "../dist/assets/Splash_background-IT8XEKFc.png";
import image_47 from "../dist/assets/trash_green-LdEzSlKa.svg";
import image_48 from "../dist/assets/trash_yellow-XHp9qzFx.svg";
import image_49 from "../dist/assets/user_01_head_locked-YUqF529B.png";
import image_50 from "../dist/assets/user_01_head-E0wvgexm.png";
import image_51 from "../dist/assets/user_02_head_locked-lUEjD286.png";
import image_52 from "../dist/assets/user_02_head-bpI6aJKw.png";
import image_53 from "../dist/assets/user_03_head_locked-2mtryKJ3.png";
import image_54 from "../dist/assets/user_03_head-mECQQTf8.png";
import image_55 from "../dist/assets/user_04_head_locked-lww8FAhh.png";
import image_56 from "../dist/assets/user_04_head-_tUMBogE.png";
import image_57 from "../dist/assets/user_05_head_locked-qQnexROW.png";
import image_58 from "../dist/assets/user_05_head-xscgu6hw.png";
import image_59 from "../dist/assets/user_06_head_locked-et_SmUwa.png";
import image_60 from "../dist/assets/user_06_head-Q0_0pGeN.png";
import image_61 from "../dist/assets/user_07_head_locked-4TofHrI0.png";
import image_62 from "../dist/assets/user_07_head-Io6VOjBf.png";
import image_63 from "../dist/assets/user_08_head_locked-ekyackz_.png";
import image_64 from "../dist/assets/user_08_head-xBB5GO2C.png";
import image_65 from "../dist/assets/user_09_head_locked-bBXUGhtZ.png";
import image_66 from "../dist/assets/user_09_head-WSfuTBOD.png";
import image_67 from "../dist/assets/user_10_head_locked--cHoblvO.png";
import image_68 from "../dist/assets/user_10_head-iB24gK0P.png";
import image_69 from "../dist/assets/user_11_head_locked-u79LyWdR.png";
import image_70 from "../dist/assets/user_11_head-yQxT3lGK.png";
import image_71 from "../dist/assets/user_12_head_locked-pLQ_-xDD.png";
import image_72 from "../dist/assets/user_12_head-E_W39H6T.png";
import image_73 from "../dist/assets/user_13_head_locked-KtqjMVpY.png";
import image_74 from "../dist/assets/user_13_head-NoMyajFb.png";
import image_75 from "../dist/assets/user_14_head_locked-CAEDFrsB.png";
import image_76 from "../dist/assets/user_14_head-0bv5VweW.png";
import image_77 from "../dist/assets/user_15_head-Mary4FQr.png";
import image_78 from "../dist/assets/v2-A09q5MG9.png";
import image_79 from "../dist/assets/v3-F0xefcXM.png";
import image_80 from "../dist/assets/v4-ysH2Lw3o.png";
import image_81 from "../dist/assets/vert_1-wTMAJxiC.png";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";

const imagesToLoad = [
	image_01,
	image_02,
	image_03,
	image_04,
	image_05,
	image_06,
	image_07,
	image_08,
	image_09,
	image_10,
	image_11,
	image_12,
	image_13,
	image_14,
	image_15,
	image_16,
	image_17,
	image_18,
	image_19,
	image_20,
	image_21,
	image_22,
	image_23,
	image_24,
	image_25,
	image_26,
	image_27,
	image_28,
	image_29,
	image_30,
	image_31,
	image_32,
	image_33,
	image_34,
	image_35,
	image_36,
	image_37,
	image_38,
	image_39,
	image_40,
	image_41,
	image_42,
	image_43,
	image_44,
	//image_45,
	image_46,
	image_47,
	image_48,
	image_49,
	image_50,
	image_51,
	image_52,
	image_53,
	image_54,
	image_55,
	image_56,
	image_57,
	image_58,
	image_59,
	image_60,
	image_61,
	image_62,
	image_63,
	image_64,
	image_65,
	image_66,
	image_67,
	image_68,
	image_69,
	image_70,
	image_71,
	image_72,
	image_73,
	image_74,
	image_75,
	image_76,
	image_77,
	image_78,
	image_79,
	image_80,
	image_81,
];

/*
const importAll = (r) => {
	
	//let images = {};
	let images = [];
	r.keys().map((item) => {
		//images[item.replace("./", "")] = r(item);
		return images.push(r(item));
	});
	return images;
};
const IMAGES = importAll(
	require.context("./images", false, /\.(png|jpe?g|svg)$/)
);
*/
let loadTimeOut: NodeJS.Timeout;
function GameLoader() {
	const [imgsLoaded, setImgsLoaded] = useState(false);
	const [callOnce, setCallOnce] = useState(false);
	const [setIsSuperMode, getData, setIsOnline] = useGameInterfaceStore(
		(state) => [state.setIsSuperMode, state.getData, state.setIsOnline]
	);
	const loadImage = (image: any) => {
		return new Promise((resolve, reject) => {
			const loadImg = new Image();
			loadImg.src = image.default;
			// wait 2 seconds to simulate loading time
			loadImg.onload = () => resolve(image);

			loadImg.onerror = (err) => {
				resolve(image);
				//reject(err)
			};
		});
	};
	useEffect(() => {
		async function fetchData() {
			if (!imgsLoaded) {
				if (!callOnce) {
					setCallOnce(true);
					try {
						const promesseA = new Promise(() => getData());
						await promesseA
							.then((val) => {
								setIsOnline(1);
							})
							.catch((error: any) => {
								setIsOnline(0);
							});
					} catch (error) {
						setIsOnline(0);
					}
					Promise.all(imagesToLoad.map((image) => loadImage(image)))
						.then(() => {
							setImgsLoaded(true);
						})
						.catch((err) => console.log("Failed to load images", err));
				}
				loadTimeOut = setTimeout(() => {
					// 	//console.log("loadImage_TimeOut");
					setImgsLoaded(true);
					clearTimeout(loadTimeOut);
				}, 5000);
			} else {
				setIsSuperMode(false);
			}
		}
		fetchData();
	}, [callOnce, imgsLoaded]);
	/*
	loadTimeOut = setTimeout(() => {
		//console.log("loadImage_TimeOut");
		setImgsLoaded(true);
		clearTimeout(loadTimeOut);
	}, 3000);
*/
	if (imgsLoaded) {
		//
	}
	return (
		<>
			{imgsLoaded ? (
				<>
					<GameSwitcher />
				</>
			) : (
				<>
					<div
						id="phoneContainer"
						className={"phoneContainer loading"}
					>
						<div className="gameContainer">
							<div className="logo_zone flex justify-center">
								<img
									src={citeo_logo_black}
									alt=""
								/>
							</div>
							<div className="text_zone">
								<span className="GillStyleBold">êtes-</span>
								<span className="novaStyleNormal">vous</span>
								<br />
								<span className="little GillStyleBold">prêts à trier ?</span>
							</div>

							<div className="load_text">Chargement...</div>
							<Spinner />
						</div>
					</div>
					<div className="invisible">
						{imagesToLoad.map((igm, index) => {
							return (
								<React.Fragment key={index}>
									<img src={igm} />
								</React.Fragment>
							);
						})}
					</div>
					<div className="phoneBottom"></div>
				</>
			)}
		</>
	);
}

export default GameLoader;
