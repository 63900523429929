import * as enums from "../vars/enums";
import trash_yellow from "../images/trash_yellow.svg";
 import trash_green from "../images/trash_green.svg";
 import b_trash_yellow from "../images/b_trash_yellow.svg";
 import b_trash_green from "../images/b_trash_green.svg";
export const UpdateTrashPosition = (percent: number) => {
	const usedPercent = percent - 10;
	const trashZoneContainer = document.getElementById("trashZoneContainer")!;
	const trash = document.getElementById("poubelle")!;
	if (trash) {
		const footer = document.getElementById("footer")!;
		const gameStartInterface = document.getElementById("gameStartInterface")!;
		const Appheader = document.getElementById("App-header")!;

		let newHeight: number = parseInt(
			((gameStartInterface.offsetHeight * usedPercent) / 100).toString()
		);

		if (newHeight > gameStartInterface.offsetHeight - 45) {
			newHeight = parseInt((gameStartInterface.offsetHeight - 45).toString());
		}
		//if (Appheader.style.height !== newHeight + "px") {
		if (Appheader.style.height !== usedPercent + "%") {
			Appheader.style.transition = "height .1s ease 0s";
			//Appheader.style.height = newHeight + "px";
			Appheader.style.height = usedPercent + "%";
			footer.style.transition = "top .1s ease 0s";
			//footer.style.top = newHeight + "px";
			footer.style.top = (usedPercent-.1) + "%";
		}

		let trashTop: string = "";
		if (newHeight - trash.offsetHeight <= newHeight) {
			trashTop =
				"calc(" + newHeight + "px - " + trash.offsetHeight + "px + .5%)";
		} else {
			trashTop = trashTop + "px";
		}
		//trash.style.transition = "top .1s ease 0s";
		//trash.style.top = trashTop;
		//trashZoneContainer.style.transition = "top .1s ease 0s";
		trashZoneContainer.style.top = trashTop;
	}
	/*let trash = document.getElementById("poubelle")!;
	if (trash) {
		trash.style.top = "calc(" + percent + "vh - 10vh)";
	}*/
};

export const generateTrash = (type: number, gameMode: number) => {
	switch (type) {
		//case enums.TRASH_TYPE_BLUE:
		// 	return trash_blue;
		// case enums.TRASH_TYPE_BROWN:
		// 	return trash_brown;
		// case enums.TRASH_TYPE_RED:
		// 	return trash_red;
		case enums.TRASH_TYPE_GREEN:

			return  gameMode === enums.GAME_MODE_EASY ? b_trash_green : trash_green;
		case enums.TRASH_TYPE_YELLOW:
		default:
			return  gameMode === enums.GAME_MODE_EASY ? b_trash_yellow : trash_yellow;
	}
};

export const getTrashType = () => {
	const poubelle_item = document.querySelector("#poubelle_item");
	if (poubelle_item) {
		return (
			parseInt(poubelle_item.getAttribute("data-trashtype")!) ??
			enums.TRASH_TYPE_YELLOW
		);
	}

	return enums.TRASH_TYPE_YELLOW;
};

export const setTrashType = (trashtype: number) => {
	const poubelle_item = document.querySelector("#poubelle_item");
	if (poubelle_item) {
		poubelle_item.setAttribute("data-trashtype", trashtype.toString());
	}
};
