/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import * as enums from "./vars/enums";
import appStoreButton from "./images/appStoreButton.png";
import googlePlayButton from "./images/googlePlayButton.png";
import play_again_button from "./images/play_again_button.png";
import papyrus_top from "./images/papyrus_top.svg";
import papyrus_bottom from "./images/papyrus_bottom.svg";
import { Event, PageView } from "./hooks/useAnalyticsEventTracker";
import { useNavigate } from "react-router-dom";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import Donation from "components/Donation";
import { CiteoUser } from "vars/types";

function GameOver() {
	const navigate = useNavigate();
	const [
		setGameStatus,
		setConfigPage,
		getScoreList,
		getSelectedUser,
		getData,
		setIsOnline,
	] = useGameInterfaceStore((state) => [
		state.setGameStatus,
		state.setConfigPage,
		state.getScoreList,
		state.getSelectedUser,
		state.getData,
		state.setIsOnline,
	]);

	const setGA = () => {
		//Prod
		//ReactGA.initialize("G-JH4MFPFJJX");
		//Dev
		//ReactGA.initialize("G-BD9SYBSEKB");
		//ReactGA.pageview("Game Over");
		//history.push("GameOver");
		//history.push("/#GameOver");
		PageView();
		Event("Game Over Page", "Game Over", "GAMEOVER_PAGE");
	};

	const scoreList = getScoreList();
	const isOnline = true; // getIsOnline() === 0 ? false : true;

	useEffect(() => {
		navigate("/#GameOver");
		setGA;
		setConfigPage(2);
		try {
			const promesseA = new Promise(() => getData());
			promesseA.then((val) => {
				setIsOnline(1);
			});
		} catch (error) {
			setIsOnline(0);
		}
	}, []);
	return (
		<>
			<div className="instructions">
				<div className="instructionsContent">
					<div className="bloc_container bloc_links">
						<div className="block_top">
							<img
								src={papyrus_top}
								alt=""
							/>
						</div>
						<div className="block_middle">
							<hr />
							<div className="sub_title">BRAVO !!!</div>
							<hr />
							<br />
							<div className="block_text">
								<div className="">
									<span className="GillStyleLight">
										Peu importe combien vous avez fait de points, vous avez bien
										rempli les bornes jaune et verte.
									</span>
								</div>
								<p className="GillStyleBold">
									Triez encore mieux en téléchargeant l'application :
								</p>
								<h5>
									<span className="GillStyleBold">Guide du tri</span>
								</h5>
								<div className="app_links">
									<p className="link_button">
										<a
											onClick={() => {
												/*Event(
													"Open Iphone App",
													"Iphone App clicked",
													"GAMEOVER_PAGE"
												);*/
											}}
											href="https://apps.apple.com/fr/app/guide-du-tri/id485709444"
											target="_blank"
										>
											<img
												src={appStoreButton}
												alt=""
											/>
										</a>
									</p>
									<div></div>
									<p className="link_button">
										<a
											onClick={() => {
												/*	Event(
													"Open Google App",
													"Google App clicked",
													"GAMEOVER_PAGE"
												);*/
											}}
											href="https://play.google.com/store/apps/details?id=fr.ecoemballage.guidedutri&gl=FR"
											target="_blank"
										>
											<img
												src={googlePlayButton}
												alt=""
											/>
										</a>
									</p>
								</div>

								<div
									className="bloc_button"
									onClick={() => {
										Event("Refresh Game", "Game renewed", "GAMEOVER_PAGE");
										setGameStatus(enums.GAMESTATUS_SPLASH);
										// props.refreshGame();
									}}
								>
									<p>
										<img
											src={play_again_button}
											alt="Rajouer"
										/>
									</p>
								</div>
							</div>
						</div>

						<div className="block_bottom">
							<img
								src={papyrus_bottom}
								alt=""
							/>
						</div>
					</div>

					{/* {isOnline ? <Donation /> : ""} */}

					<div className="bloc_container bloc_links">
						<div className="block_top">
							<img
								src={papyrus_top}
								alt=""
							/>
						</div>
						<div className="block_middle">
							<hr />
							<div className="sub_title">CLASSEMENT </div>
							<div className="sub_sub_title">nos champions du tri</div>
							<hr />
							<div className="block_text">
								<div className="scoreTab">
									{scoreList.map((player: CiteoUser, index: number) => {
										return (
											<div
												key={index}
												className={
													"listItem " +
													(player.userId === getSelectedUser()
														? " player_me "
														: "")
												}
											>
												<div className="user_rank novaStyleLight">
													{index + 1}
												</div>
												<div className="user_pic">
													<img
														src={player.src}
														alt=""
													/>
												</div>
												<div className="user_details">
													<div className="user_name GillStyleLight">
														{player.name}
													</div>
													<div className="user_score">{player.score}pts</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="block_bottom">
							<img
								src={papyrus_bottom}
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default GameOver;
