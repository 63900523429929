
import logo_outer from "./images/logo_outer.png";
import logo_inner from "./images/logo_inner.png";

function Spiner() {
	return (
		<div className="spinnContent">
			<div className="loader_outer" >
				<img
					src={logo_outer}
					alt=""
				/>
			</div>
			<div className="loader_inner">
				<img
					src={logo_inner}
					alt=""
				/>
			</div>
		</div>
	)
}

export default Spiner;
