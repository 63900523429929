import GameInstructions from "GameInstructions";
import GameOver from "GameOver";
import GameSplash from "GameSplash";
import GameStart from "GameStart";
import React from "react";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import * as enums from "vars/enums";

function GameSwitcher() {
	const [getGameStatus, updatedAt] = useGameInterfaceStore((state) => [
		state.getGameStatus,
		state.updatedAt,
	]);

	const gameStatus = getGameStatus();

	//console.log(`gameStatus : ${gameStatus}`);
	//console.log(`instructionPage : ${getInstructionPage()}`);
	const isSuperMode = false; //functions. getIsSuperMode();
	const mobileType = 0; // functions.getMobileType();

	return (
		<>
			<div
				className={` flex h-full w-full phoneContainer rounded-md overflow-hidden ${
					isSuperMode ? "supermode" : ""
				} mobile_type_${mobileType}`}
			>
				<div className="gameContainer flex h-full w-full rounded-md overflow-hidden">
					{gameStatus === enums.GAMESTATUS_OVER ? (
						<GameOver />
					) : gameStatus === enums.GAMESTATUS_START ? (
						<GameStart />
					) : gameStatus === enums.GAMESTATUS_SPLASH ? (
						<GameSplash />
					) : (
						<GameInstructions />
					)}
				</div>
			</div>

			<div className="phoneBottom"></div>
		</>
	);
}

export default GameSwitcher;
