import styled from "styled-components";

export const StyledTrashImage = styled.img``
// export const StyledTrashImage = styled.img`
// 	//position: absolute;
// 	//top: calc(
// 	//	${(props) => parseInt((parseInt(props["data-nbLife"] ) / parseInt(props["data-totalLife"])) * 100)}% - 15% -
// 	//		131px
// 	//);
// `;
