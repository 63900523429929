import j1 from "../images/j1.png";
import j2 from "../images/j2.png";
import j3 from "../images/j3.png";
import j4 from "../images/j4.png";
import j5 from "../images/j5.png";
import j6 from "../images/j6.png";
import j7 from "../images/j7.png";
import j8 from "../images/j8.png";
import j9 from "../images/j9.png";
import j10 from "../images/j10.png";
import j11 from "../images/j11.png";
import j12 from "../images/j12.png";
import j13 from "../images/j13.png";
import j14 from "../images/j14.png";
import j15 from "../images/j15.png";
import j16 from "../images/j16.png";
import j17 from "../images/j17.png";
import j18 from "../images/j18.png";
import j19 from "../images/j19.png";
import j20 from "../images/j20.png";
import j21 from "../images/j21.png";
import j22 from "../images/j22.png";
import j23 from "../images/j23.png";
import j24 from "../images/j24.png";

import v1 from "../images/v1.png";
import v2 from "../images/v2.png";
import v3 from "../images/v3.png";
import v4 from "../images/v4.png";
import v5 from "../images/v5.png";

import user_01_head from "../images/user_01_head.png";
import user_02_head from "../images/user_02_head.png";
import user_03_head from "../images/user_03_head.png";
import user_04_head from "../images/user_04_head.png";
import user_05_head from "../images/user_05_head.png";
import user_06_head from "../images/user_06_head.png";
import user_07_head from "../images/user_07_head.png";
import user_08_head from "../images/user_08_head.png";
import user_09_head from "../images/user_09_head.png";
import user_10_head from "../images/user_10_head.png";
import user_11_head from "../images/user_11_head.png";
import user_12_head from "../images/user_12_head.png";
import user_13_head from "../images/user_13_head.png";
import user_14_head from "../images/user_14_head.png";

import user_01_locked from "../images/user_01_head_locked.png";
import user_02_locked from "../images/user_02_head_locked.png";
import user_03_locked from "../images/user_03_head_locked.png";
import user_04_locked from "../images/user_04_head_locked.png";
import user_05_locked from "../images/user_05_head_locked.png";
import user_06_locked from "../images/user_06_head_locked.png";
import user_07_locked from "../images/user_07_head_locked.png";
import user_08_locked from "../images/user_08_head_locked.png";
import user_09_locked from "../images/user_09_head_locked.png";
import user_10_locked from "../images/user_10_head_locked.png";
import user_11_locked from "../images/user_11_head_locked.png";
import user_12_locked from "../images/user_12_head_locked.png";
import user_13_locked from "../images/user_13_head_locked.png";
import user_14_locked from "../images/user_14_head_locked.png";

import no_garb from "../images/no_garb.svg";

export const no_garbageImg = no_garb;

export const MAX_WIDTH = 270;
export const INIT_NBLIFE = 20;
export const nBElementsToShow = 20;
export const nBElementsToShowOnSplash = 50;
export const nBGarbage = 24;
export const MAX_INSTRUCTION_PAGE = 5;
export const NB_COLUMNS = 7;
export const DEFAULT_DIFFICULTY = 5;
export const DEFAULT_SPEED = 5;

export const SCORE_ADD_NORMAL = 50;
export const SCORE_ADD_HARD = 100;
export const SCORE_ADD_SUPERMODE = 200;
export const SCORE_CHECKPOINT = 500;
export const MAX_SPEED = 75;
export const SUPERMODE_DURATION = 10;

export const TRASH_SMALL_WIDTH = "95";
export const TRASH_BIG_WIDTH = "140";

export const USERSCORE_TOTAL = 100000;
export const NBUSERS_TOTAL = 13;

export const INIT_Y = -100;

export const WINNER_PRICE = 5;

export const yElems = [
	{ src: j1, title: "Prospectus papier" },
	{ src: j2, title: "Magasines papier" },
	{ src: j3, title: "Journaux papier" },
	{ src: j4, title: "Bombe Aérosol" },
	{ src: j5, title: "Boite de conserve" },
	{ src: j6, title: "Canette métallique" },
	{ src: j7, title: "Contenant plastique" },
	{ src: j8, title: "Flacon gel douche" },
	{ src: j9, title: "Bouteille plastique" },
	{ src: j10, title: "Emballage plastique" },
	{ src: j11, title: "Bouteille plastique" },
	{ src: j12, title: "Boite de céréales" },
	{ src: j13, title: "Sachet en carton" },
	{ src: j14, title: "Boite d'oeufs" },
	{ src: j15, title: "Tube carton" },
	{ src: j15, title: "Tube carton" },
	{ src: j16, title: "Tube carton" },
	{ src: j17, title: "Tube carton" },
	{ src: j18, title: "Tube carton" },
	{ src: j19, title: "Tube carton" },
	{ src: j20, title: "Tube carton" },
	{ src: j21, title: "Tube carton" },
	{ src: j22, title: "Tube carton" },
	{ src: j23, title: "Tube carton" },
	{ src: j24, title: "Tube carton" },
];

export const gElems = [
	{ src: v1, title: "Bouteille en verre" },
	{ src: v2, title: "Bouteille en verre" },
	{ src: v3, title: "Bouteille en verre" },
	{ src: v4, title: "Flacon en verre" },
	{ src: v5, title: "Bouteille en verre" },
];

export const nBGarbageOnSplash = yElems.length + gElems.length;

export const users = [
	{
		username: "Julie",
		name: "Erica",
		userId: 4,
		src: user_04_head,
		disabled_src: user_04_locked,
	},
	{
		username: "Yann",
		name: "Géralde",
		userId: 2,
		src: user_02_head,
		disabled_src: user_02_locked,
	},
	{
		username: "Pauline",
		name: "Carla",
		userId: 7,
		src: user_07_head,
		disabled_src: user_07_locked,
	},
	{
		username: "Géraldine",
		name: "Christine",
		userId: 8,
		src: user_08_head,
		disabled_src: user_08_locked,
	},
	{
		username: "Valérie",
		name: "Delphine",
		userId: 5,
		src: user_05_head,
		disabled_src: user_05_locked,
	},
	{
		username: "Cécile",
		name: "Émilie",
		userId: 3,
		src: user_03_head,
		disabled_src: user_03_locked,
	},
	{
		username: "Aurélie",
		name: "Laura",
		userId: 1,
		src: user_01_head,
		disabled_src: user_01_locked,
	},
	{
		username: "Carl",
		name: "Aurélien",
		userId: 6,
		src: user_06_head,
		disabled_src: user_06_locked,
	},
	{
		username: "Eric",
		name: "Paul",
		userId: 9,
		src: user_09_head,
		disabled_src: user_09_locked,
	},
	{
		username: "Christophe",
		name: "Julien",
		userId: 10,
		src: user_10_head,
		disabled_src: user_10_locked,
	},
	{
		username: "Sandra",
		name: "Juliette",
		userId: 11,
		src: user_11_head,
		disabled_src: user_11_locked,
	},
	{
		username: "Adeline",
		name: "Carine",
		userId: 12,
		src: user_12_head,
		disabled_src: user_12_locked,
	},
	{
		username: "Isabelle",
		name: "Stéphanie",
		userId: 14,
		src: user_14_head,
		disabled_src: user_14_locked,
	},
	{
		username: "Stéphane",
		name: "Adel",
		userId: 13,
		src: user_13_head,
		disabled_src: user_13_locked,
	},
];

export const STAGE_HEIGHT: number = 20;
export const STAGE_WIDTH :number= 12;

export const createStage = () => {
	Array.from(
		Array(STAGE_HEIGHT),
		() => new Array(STAGE_WIDTH).fill(0)
	);
};
