/* eslint-disable @typescript-eslint/no-unused-vars */
import  {
	
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import { useNavigate } from "react-router-dom";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import * as enums from "vars/enums";
import objectif_1 from "./images/objectif_1.svg";
import path_arrow from "./images/path_arrow.png";
import drag_green_trash from "./images/drag_green_trash.svg";
import jaune_1 from "./images/jaune_1.png";
import jaune_2 from "./images/jaune_2.png";
import jaune_3 from "./images/jaune_3.png";
import jaune_4 from "./images/jaune_4.png";
import bac_jaune from "./images/bac_jaune.png";
import vert_1 from "./images/vert_1.png";
import borne_verte from "./images/borne_verte.png";
import snooz from "./images/snooz.gif";
import no_snooz from "./images/no_snooz.gif";
import bottle_speed from "./images/bottle_speed.png";
import passer_button from "./images/passer_button.png";
import papyrus_top from "./images/papyrus_top.svg";
import papyrus_bottom from "./images/papyrus_bottom.svg";

gsap.registerPlugin(Draggable);

function GameInstructions() {
	const navigate = useNavigate();
	const [
		setInstructionPage,
		getInstructionPage,
		setGameStatus,
		getData,
		setIsOnline,
	] = useGameInterfaceStore((state) => [
		state.setInstructionPage,
		state.getInstructionPage,
		state.setGameStatus,
		state.getData,
		state.setIsOnline,
	]);
	const [isYellowTrash, setisYellowTrash] = useState(true);

	const pickerRef = useRef(null);
	const cellsRef = useRef(null);

	useLayoutEffect(() => {
		gsap.defaults({ ease: "none" });
		const ctx = gsap.context(() => {
			const picker = pickerRef.current;
			const cells = cellsRef.current;
			const proxy = document.querySelector("#poubelle_1");
			const myWrapper = gsap.utils.wrap(0, 1);

			const baseTl = gsap.timeline({ paused: true });

			gsap.set(picker, {
				//width: wrapWidth - cellWidth,
			});
			// cells.forEach((cell, i) => {
			// 	initCell(cell, i);
			// });

			gsap.set(cells, { x: 0 });

			const tl = gsap.timeline({ repeat: 1 });

			const draggable = Draggable.create(proxy, {
				type: "x",
				trigger: picker,
				dragResistance: 0.01,
				dragClickables: true,
				bounds: "#tuto_1",
				//liveSnap: onLiveSnap,
				allowContextMenu: true,
				onDrag: function () {
					const poub = document.querySelector("#poubelle_1");
					const chemin = document.querySelector("#tuto_1");
					const papyrus = document.querySelector("#instruction_1");
					if (this.hitTest("#arrivalTest")) {
						//console.log("dépassé !!!!!!!!!!!!!!");
						papyrus?.classList.add("validated");
						setTimeout(() => {
							setInstructionPage(enums.INTRUCTION_PAGE_INTRO_2);
						}, 3000);
					}
				},
				onThrowComplete: () => {
					//console.log("onThrowComplete");
					// TODO: animation that injects selected card title
				},
			})[0];
		});
		return () => ctx.revert();
	}, []);

	const changeTrash = () => {
		setisYellowTrash(!isYellowTrash);
	};

	
	useEffect(() => {
		navigate("/#Instructions");
		try {
			const promesseA = new Promise(() => getData());
			promesseA.then((val) => {
				setIsOnline(1);
			});
		} catch (error) {
			setIsOnline(0);
		}
	}, []);

	return (
		<div className="instructions">
			<div className="instructionsContent">
				{getInstructionPage() === enums.INTRUCTION_PAGE_INTRO_1 ? (
					<div
						id="instruction_1"
						className="bloc_container"
					>
						<div className="block_top">
							<img
								src={papyrus_top}
								alt=""
							/>
						</div>
						<div className="block_middle flex flex-col justify-center">
							<hr />
							<div className="sub_title">Instructions</div>
							<hr />
							<p className="cagnotte_lbl novaStyleBold">
								Déplacez le bac de gauche à droite pour recevoir les déchets{" "}
							</p>
							<div className="playground">
								<div
									ref={pickerRef}
									id="tuto_1"
									className="tuto_1 picker"
								>
									<div
										id="poubelle_1"
										ref={cellsRef}
										className="depart draggable sleep"
									>
										<img
											className="awake"
											src={no_snooz}
											alt=""
										/>
										<img
											className="snooz"
											src={snooz}
											alt=""
										/>
									</div>
									<div className="travel">
										<img
											src={path_arrow}
											alt=""
										/>
									</div>
									<div
										id="arrivee_1"
										className="arrivee"
									>
										<img
											src={objectif_1}
											alt=""
										/>
									</div>
									<div
										id="arrivalTest"
										className="w-[1px]"
									></div>
								</div>
								<br /> &nbsp;
								<br /> &nbsp;
								<br /> &nbsp;
								<br /> &nbsp;
							</div>
							<div className="tuto novaStyleBold">
								<img
									src={bac_jaune}
									alt=""
								/>
								<div className="itemList">
									<img
										src={jaune_1}
										alt=""
									/>
									<img
										src={jaune_2}
										alt=""
									/>
									<img
										src={jaune_3}
										alt=""
									/>
									<img
										src={jaune_4}
										alt=""
									/>
								</div>
							</div>
							<div
								className="bloc_button"
								onClick={() => {
									setInstructionPage(enums.INTRUCTION_PAGE_INTRO_2);
								}}
							>
								<img
									src={passer_button}
									alt="passer"
								/>
							</div>
						</div>

						<div className="block_bottom">
							<img
								src={papyrus_bottom}
								alt=""
							/>
						</div>
					</div>
				) : (
					<div
						id="instruction_2"
						className={"bloc_container " + (isYellowTrash ? "" : " validated")}
					>
						<div className="block_top">
							<img
								src={papyrus_top}
								alt=""
							/>
						</div>
						<div className="block_middle">
							<hr />
							<div className="sub_title">Instructions</div>
							<hr />
							<p className="cagnotte_lbl novaStyleBold">
								Tapez/cliquez pour switcher entre la borne jaune et la borne
								verte
							</p>

							<div className="playground">
								<div className="garb">
									<img
										src={bottle_speed}
										alt=""
									/>
								</div>
								<div
									id="tuto_2"
									className="tuto_2"
								>
									<div className="travel"></div>
									<div
										id="poubelle_2"
										className="depart sleep"
										onClick={() => {
											changeTrash();
											setTimeout(() => {
												setInstructionPage(enums.INTRUCTION_PAGE_CONFIG);
												setGameStatus(enums.GAMESTATUS_SPLASH);
											}, 5000);
										}}
									>
										{isYellowTrash ? (
											<>
												<img
													className="awake"
													src={no_snooz}
													alt=""
												/>
												<img
													className="snooz"
													src={snooz}
													alt=""
												/>
											</>
										) : (
											<img
												src={drag_green_trash}
												alt=""
											/>
										)}
									</div>
									<div className="arrivee"></div>
								</div>
								<br /> &nbsp;
								<br /> &nbsp;
								<br /> &nbsp;
								<br /> &nbsp;
							</div>
							<div className="tuto green novaStyleBold">
								<img
									src={borne_verte}
									alt=""
								/>
								<div className="itemList">
									<img
										src={vert_1}
										alt=""
									/>
								</div>
							</div>
							<div
								className="bloc_button"
								onClick={() => {
									setInstructionPage(enums.INTRUCTION_PAGE_CONFIG);
									setGameStatus(enums.GAMESTATUS_SPLASH);
								}}
							>
								<img
									src={passer_button}
									alt="passer"
								/>
							</div>
						</div>
						<div className="block_bottom">
							<img
								src={papyrus_bottom}
								alt=""
							/>
						</div>
					</div>
				)}

				{/*<div
					className="bloc_button"
					onClick={() => {
						functions.setInstructionPage(enums.INTRUCTION_PAGE_CONFIG);
						this.props.splashGoTo(1);
					}}
				>
					<img src={continue_button} alt="Continuer" />
				</div>*/}
			</div>
		</div>
	);
}

export default GameInstructions;
