import styled from "styled-components";

export const StyledGameZone = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props["data-nbColumns"]}, 1fr);
	/*background-color: red;*/
	height: -webkit-fill-available;
	grid-auto-rows: ${(props) =>
		parseInt(((1 / parseInt(props["data-nbRows"] ?? "1")) * 100).toString())}%;
`;
