import ReactGA from "react-ga";

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label,
	});
};

export const PageView = () => {
	if (import.meta.env.NODE_ENV === "production") {
		ReactGA.pageview(window.location.pathname + window.location.search);

		//console.log("journalisation immédiate");
	}
};

export const initGA = (trackingID) => {
	if (import.meta.env.NODE_ENV === "production") {
		ReactGA.initialize(trackingID);
	}
};
