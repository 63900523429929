/* eslint-disable @typescript-eslint/no-unused-vars */
import  {
	
	useEffect,
	useLayoutEffect,
	
	useState,
} from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import { useNavigate } from "react-router-dom";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import * as enums from "vars/enums";
import play_button from "./images/play_button.png";
import papyrus_top from "./images/papyrus_top.svg";
import papyrus_bottom from "./images/papyrus_bottom.svg";
import * as variables from "vars/variables";
import { Event, PageView } from "./hooks/useAnalyticsEventTracker";
import Donation from "components/Donation";
import ProgressionBar from "components/ProgressionBar";

gsap.registerPlugin(Draggable);

function GameSplash() {
	const navigate = useNavigate();
	const [
		setGameStatus,
		getSelectedUser,
		setSelectedUser,
		getUserScore,
		getIsOnline,
		getData,
		setIsOnline,
		setInstructionPage,
		setScore,
		setIsSuperMode,
		setNbLife,
		getCurrCagnotte,
		setConfigPage,
	] = useGameInterfaceStore((state) => [
		state.setGameStatus,
		state.getSelectedUser,
		state.setSelectedUser,
		state.getUserScore,
		state.getIsOnline,
		state.getData,
		state.setIsOnline,
		state.setInstructionPage,
		state.setScore,
		state.setIsSuperMode,
		state.setNbLife,
		state.getCurrCagnotte,
		state.setConfigPage,
	]);


	let selectedUser = getSelectedUser();
	if (!selectedUser) {
		selectedUser = variables.users[0].userId;
		setSelectedUser(selectedUser);
	}

	const USERSCORE_TOTAL = variables.USERSCORE_TOTAL;
	const NBUSERS_TOTAL = variables.NBUSERS_TOTAL;
	const users = variables.users;
	const uScore = getUserScore()
	const currScore = uScore ? uScore : 0;
	
	const [isOnline, setisOnline] = useState(getIsOnline() === 0 ? false : true);
	const progress = (currScore / USERSCORE_TOTAL) * 100;

	const total = parseInt(
		(
			(currScore > 0 ? currScore / USERSCORE_TOTAL : 0) * NBUSERS_TOTAL
		).toString()
	);

	const nbUserLeft = NBUSERS_TOTAL - total;

	const nbUsers = JSON.parse(JSON.stringify(users));
	nbUsers.splice(total + 1, nbUserLeft);
	//let noStarTotal = NBUSERS_TOTAL - nbUsers.length;
	const nbDisabledUsers = JSON.parse(JSON.stringify(users));
	nbDisabledUsers.splice(0, total + 1);

	const setGA = () => {
		//Prod
		//ReactGA.initialize("G-JH4MFPFJJX");
		//Dev
		//ReactGA.initialize("G-BD9SYBSEKB");
		//ReactGA.pageview("Splash Screen");
		//history.push("/#SplashScreen");
		//console.log("getCagnotte");
		getCurrCagnotte();
		Event("Splash Screen Page", "Splash Screen", "SPLASHSCREEN_PAGE");
		PageView();
	};

	useLayoutEffect(() => {}, []);

	useEffect(() => {
		navigate("/#SplashScreen");
		setGA();		
		setScore(0);
		setConfigPage(1);
		try {
			const promesseA = new Promise(() => getData());
			promesseA.then((val) => {
				setIsOnline(1);
			});
		} catch (error) {
			setIsOnline(0);
		}
	}, []);

	return (
		<>
			<div className="instructions">
				<div className="instructionsContent">
					{isOnline ? <Donation /> : ""}
					<div className="bloc_container">
						<div className="block_top">
							<img
								src={papyrus_top}
								alt=""
							/>
						</div>
						<div className="block_middle">
							<hr />
							<div className="sub_title">Nouvelle partie</div>
							<div className="sub_sub_title">choix du personnage </div>
							<hr />

							<div className="userContainer">
								<p className="cagnotte_lbl novaStyleBold">
									Faites monter votre score et débloquez de nouveaux personnages
								</p>
								<ProgressionBar
									totalToReach={variables.USERSCORE_TOTAL}
									nbPoints={getUserScore() ? getUserScore() : 1}
									nbUsers={nbUsers}
									nbDisabledUsers={nbDisabledUsers}
									progress={progress}
									hasStars={false}
									currency={""}
								/>
								<div className="caraSelection">
									{users.map((user, index) => {
										return (
											<div
												key={index}
												className={
													"userBadge " +
													(user.userId === selectedUser ? " active" : "")
												}
												onClick={() => {
													if (index <= total) {
														setSelectedUser(user.userId);
														setSelectedUser(user.userId);
													}
												}}
											>
												<img
													src={index <= total ? user.src : user.disabled_src}
													alt=""
												/>
												<div className="userName">{user.name}</div>
											</div>
										);
									})}
								</div>
								<div
									className="bloc_button"
									onClick={() => {
										setInstructionPage(enums.INTRUCTION_PAGE_CONFIG);
										setScore(0);
										//this.props.callBack();
										setIsSuperMode(false);
										setNbLife(variables.INIT_NBLIFE);
										setGameStatus(enums.GAMESTATUS_START);
									}}
								>
									<p>
										<img
											src={play_button}
											alt="Jouer"
										/>
									</p>
								</div>
							</div>
						</div>
						<div className="block_bottom">
							<img
								src={papyrus_bottom}
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default GameSplash;
