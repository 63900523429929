/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import  { useEffect,   useState } from "react";
import * as enums from "../vars/enums";
import * as variables from "../vars/variables";
import * as gameFunctions from "../vars/gameFunctions";

import gameOver from "../images/gameOver.png";

import { useInterval } from "../hooks/useInterval";
import logo from "../images/logo.svg";

import user_15_head from "../images/user_15_head.png";
import goodImg from "../images/good.png";
import badImg from "../images/bad.png";
import powerup from "../images/powerup.gif";
import { CiteoUser, GarbageProperties } from "vars/types";

import { StyledPlayableZone } from "styles/StyledPlayableZone";
import { StyledHeader } from "styles/StyledHeader";
import { StyledGameZone } from "styles/StyledGameZone";
import { StyledGarbageImg } from "styles/StyledGarbageImg";
import { StyledFooter } from "styles/StyledFooter";
import { StyledGoodPoint, StyledGoodPointImg } from "styles/StyledGoodPoint";
import { useGameInterfaceStore } from "stores/useGameInterfaceStore";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(Draggable);

const nbColumns = variables.NB_COLUMNS;

const refreshInterval = 50;
let gameOverCount = 0;
let powerUpCount = 0;
let goodPointCount = 0;
function Stage({ ...props }) {
	const [
		getNbLife,
		getGameMode,
		getIsSuperMode,
		setScore,
		setNbLife,
		getScore,
		getSelectedUser,
		getUserScore,
		setGarbageType,
		setUserScore,
		setIsSuperMode,
		setGameStatus,
		getCagnotte,
		setCagnotte,
		getGarbageType,
		getGameStatus,
		setScoreList,
	] = useGameInterfaceStore((state) => [
		state.getNbLife,
		state.getGameMode,
		state.getIsSuperMode,
		state.setScore,
		state.setNbLife,
		state.getScore,
		state.getSelectedUser,
		state.getUserScore,
		state.setGarbageType,
		state.setUserScore,
		state.setIsSuperMode,
		state.setGameStatus,
		state.getCagnotte,
		state.setCagnotte,
		state.getGarbageType,
		state.getGameStatus,
		state.setScoreList,
	]);

	const [hasGarbage, setHasGarbage] = useState(false);
	const [isGarbageActive, setIsGarbageActive] = useState(false);
	const [garbage, setGarbage] = useState<JSX.Element | null>(null);
	const [garbageProperties, setGarbageProperties] = useState(null);

	const [isGoodPoint, setIsGoodPoint] = useState(false);
	const [hasGoodPoint, setHasGoodPoint] = useState(false);

	const [isPlay, setIsPlayt] = useState(false);

	let superModeInterval: NodeJS.Timeout;
	let isInitialized = false;

	const getCaseWidth = () => {
		const game_zone = document.getElementById("game_zone")!;
		const totalWidth = game_zone.getBoundingClientRect().width;
		return parseInt((totalWidth / nbColumns).toString());
	};

	const renewGarbage = (garbageItem: HTMLElement) => {
		//console.log("renewGarbage");
		const newGarb = generateRain();
		setGarbage(newGarb.elem);
		//setGarbageProperties(newGarb.properties);
		setGarbageType(getTrashTypeForGarbage(newGarb.properties.garbage_type));
		let newPosition = Math.floor(Math.random() * (nbColumns - 1));
		if (newPosition === 0) {
			newPosition = 1;
		}

		//updataData(true);

		const pointContainer = document.getElementById("pointContainer")!;
		const caseWidth = getCaseWidth();
		const position = newPosition * caseWidth;

		if (garbageItem && pointContainer) {
			const game_zone = document.getElementById("game_zone");
			let newItem = garbageItem.cloneNode(true);
			newItem.style.visibility = "hidden";
			newItem.src = getImageFromGarbageType(newGarb.properties.garbage_type);
			newItem.style.top = "-250px";
			newItem.style.width = caseWidth + "px";
			newItem.style.left = position + "px";
			pointContainer.style.left = position + "px";
			newItem.style.transition = "visibility ease 0.1s";
			newItem.style.visibility = "visible";
			game_zone.removeChild(garbageItem);
			game_zone.appendChild(newItem);
		}

		setHasGarbage(true);
		setIsGarbageActive(true);
	};

	const destroyGarbage = (garbageItem: HTMLElement) => {
		if (garbageItem) {
			garbageItem.style.visibility = "hidden";
			garbageItem.classList.add("disabled");
		}
		setHasGarbage(false);
	};
	const getSpeed = () => {
		//const defaulSpeed = variables.DEFAULT_SPEED;
		const speed = Math.round(getScore() / variables.SCORE_CHECKPOINT) / 3;
		return speed;
	};

	const getRandomArbitrary = (min: number, max: number) => {
		return Math.random() * (max - min) + min;
	};

	const generateScoreList = () => {
		const tmpLIst: CiteoUser[] = JSON.parse(JSON.stringify(variables.users));
		const selectedUser = getSelectedUser();
		const useCurrentScore = getScore();
		const userScore = getUserScore();
		tmpLIst.forEach((user) => {
			if (user.userId === selectedUser) {
				user.score = useCurrentScore;
			} else {
				user.score = parseInt(
					parseInt(getRandomArbitrary(21, 80).toString()) + "00"
				);
			}
		});
		const citeoUser = {
			name: "CITEO",
			score: 10000,
			userId: 15,
			src: user_15_head,
		};
		tmpLIst.push(citeoUser);
		const newScore = userScore + useCurrentScore;
		setUserScore(newScore);
		//setScore(0);
		const retrunTab = tmpLIst.sort((a, b) => (a.score < b.score ? 1 : -1));
		return retrunTab; //.splice(0, 10);
	};

	const initGame = () => {
		//gameFunctions.UpdateTrashPosition(100);
	};

	const checkOrientation = () => {
		if (window.innerWidth < window.innerHeight) {
			// vérfier la taille ?
		}
		setIsPlayt(true);
	};

	const moveGarbage = () => {
		checkOrientation();
		if (isPlay) {
			const nbLife = getNbLife();

			gameFunctions.UpdateTrashPosition(
				parseInt(((nbLife / variables.INIT_NBLIFE) * 100).toString())
			);
			//console.log("nbLife :", nbLife, "   gameOverCount:", gameOverCount);
			const trashZoneContainer = document.getElementById("trashZoneContainer")!;
			const trash = document.getElementById("poubelle")!;
			const avatarIcon = document.getElementById("avatarIcon")!;
			const gameStartInterface = document.getElementById("gameStartInterface")!;
			const Appheader = document.getElementById("App-header")!;
			const garbage_item = document.getElementById("garbage_item")!;
			const pointContainer = document.getElementById("pointContainer")!;
			const footer = document.getElementById("footer")!;

			if (garbage_item) {
				const caseWidth = getCaseWidth();
				garbage_item.style.width = caseWidth + "px";
			}

			//const game_zone = document.getElementById("game_zone")!;
			//const canGarbage = !pointContainer.classList.contains("active");
			if (
				trashZoneContainer &&
				trash &&
				avatarIcon &&
				gameStartInterface &&
				Appheader &&
				pointContainer &&
				footer
			) {
				if (
					trashZoneContainer.offsetTop <
					avatarIcon.offsetTop + avatarIcon.offsetHeight
				) {
					if (!Appheader.classList.contains("gameOver")) {
						gameStartInterface.classList.remove("life_" + nbLife);
						gameStartInterface.classList.add("gameOver");
						trashZoneContainer.classList.add("gameOver");
						Appheader.classList.remove("life_" + nbLife);
						Appheader.classList.add("gameOver");
					}
					
					setIsSuperMode(false);
					setNbLife(0);
					// const loadTimeOut = setTimeout(() => {
					// 	//console.log("loadImage_TimeOut");
					// 	setGameStatus(enums.GAMESTATUS_OVER);
					// 	clearTimeout(loadTimeOut);
					// }, 4000);
				}

				const isSuperMode = getIsSuperMode();
				if (isSuperMode) {
					powerUpCount++;
				}
				if (isSuperMode && powerUpCount >= 40) {
					props.addLife(1);
					powerUpCount = 0;
				}
				if (nbLife <= 0) {
					gameOverCount++;
				} else {
					gameOverCount = 0;
				}
				if (gameOverCount >= 100) {
					const scoreList = generateScoreList();
					setScoreList(scoreList);

					const currScore = getUserScore();
					let currCagnotte = getCagnotte();
					if (currScore >= enums.NB_POINT_TO_REACH) {
						setCagnotte(currCagnotte + 5);
						currCagnotte = currCagnotte + 5;
					}

					//props.goToScore();
					setGameStatus(enums.GAMESTATUS_OVER);
				} else {
					garbUpdate(
						isSuperMode,
						garbage_item,
						footer,
						trashZoneContainer,
						pointContainer
					);
				}
			}
		}
	};

	//clearInterval(this.hitTst);
	const nbLife = getNbLife();
	const STAGE_WIDTH = nbColumns;
	const STAGE_HEIGHT = nbLife + 20;

	//let speed = props.speed - (getIsSuperMode() ? 0.5 : 0);

	function getTrashTypeForGarbage(garbage_type: number) {
		const ttlJaune = variables.yElems.length;
		const ttlVert = variables.gElems.length;

		const tmpNum = garbage_type - ttlJaune;
		if (garbage_type < ttlJaune) {
			return enums.TRASH_TYPE_YELLOW;
		} else if (tmpNum < ttlVert) {
			return enums.TRASH_TYPE_GREEN;
		}

		return enums.TRASH_TYPE_ALL;
	}

	const garbUpdate = (
		isSuperMode: number,
		garbage_item: HTMLElement,
		footer: HTMLElement,
		trashZoneContainer: HTMLElement,
		pointContainer: HTMLElement
	) => {
		const garbageSpeed = 20;
		const superModeSpeedAcceleration = isSuperMode ? 0.6 * garbageSpeed : 0;
		const scoreSpeed = getSpeed() * garbageSpeed;

		const tmptotalSpeed = parseInt(
			(garbageSpeed + scoreSpeed + superModeSpeedAcceleration).toString()
		);

		let totalSpeed =
			tmptotalSpeed > variables.MAX_SPEED ? variables.MAX_SPEED : tmptotalSpeed;

		if (
			garbage_item &&
			footer &&
			garbage_item.getBoundingClientRect().top >
				footer.getBoundingClientRect().top
		) {
			if (totalSpeed > variables.MAX_SPEED) {
				totalSpeed = variables.MAX_SPEED;
			}
		}

		//console.log("totalSpeed:", totalSpeed);

		if (garbage_item && hasGarbage && !hasGoodPoint) {
			// Déchet tombe
			const currTop = garbage_item.getBoundingClientRect().top;
			garbage_item.style.transition = "top .1s ease 0s";
			garbage_item.style.top = currTop + totalSpeed + "px";
		}

		// Si le déchet "garbage" est en dessous de la poubelle "trash"
		if (
			trashZoneContainer &&
			garbage_item &&
			pointContainer &&
			trashZoneContainer.getBoundingClientRect().top +
				trashZoneContainer.getBoundingClientRect().height <
				garbage_item.getBoundingClientRect().top +
					garbage_item.getBoundingClientRect().height
		) {
			pointContainer.style.top = "100%";
			destroyGarbage(garbage_item);
		}

		// Si il y a un "like"
		if (hasGoodPoint) {
			goodPointCount++;
			//console.log("goodPointCount:", goodPointCount);
			if (
				trashZoneContainer.getBoundingClientRect().top - 200 <
					pointContainer.getBoundingClientRect().top &&
				goodPointCount < 20
			) {
				const currpointContainerTop =
					pointContainer.getBoundingClientRect().top;
				pointContainer.style.transition = "top .1s ease 0s";
				pointContainer.style.top = currpointContainerTop - 25 + "px";
			} else {
				setHasGoodPoint(false);
				goodPointCount = 0;
			}
		}

		if (!hasGarbage && !hasGoodPoint) {
			renewGarbage(garbage_item);
		}
	};

	const getImageFromGarbageType = (garbage_type: number) => {
		const tType = getTrashTypeForGarbage(garbage_type);
		const ttlJaune = variables.yElems.length;
		//let ttlVert = variables.gElems.length;

		const tmpNum = garbage_type - ttlJaune;
		switch (tType) {
			case enums.TRASH_TYPE_YELLOW:
				return variables.yElems[garbage_type].src;
			case enums.TRASH_TYPE_GREEN:
				return variables.gElems[tmpNum].src;
			case enums.TRASH_TYPE_ALL:
				return logo;
			default:
				return null;
		}
	};

	function generateGarbage(garbage_type: number, style: GarbageProperties) {
		//const STAGE_WIDTH = nbColumns;
		//const STAGE_HEIGHT =getNbLife() + 10;

		const tType = getTrashTypeForGarbage(garbage_type);

		const ttlJaune = variables.yElems.length;
		//let ttlVert = variables.gElems.length;

		const tmpNum = garbage_type - ttlJaune;

		switch (tType) {
			case enums.TRASH_TYPE_YELLOW:
				return (
					<StyledGarbageImg
						{...style}
						type={enums.TRASH_TYPE_YELLOW}
						trash_type={enums.TRASH_TYPE_YELLOW}
						src={variables.yElems[garbage_type].src}
						alt=""
					/>
				);
			case enums.TRASH_TYPE_GREEN:
				return (
					<StyledGarbageImg
						{...style}
						type={enums.TRASH_TYPE_GREEN}
						trash_type={enums.TRASH_TYPE_GREEN}
						src={variables.gElems[tmpNum].src}
						alt=""
					/>
				);
			case enums.TRASH_TYPE_ALL:
				return (
					<StyledGarbageImg
						{...style}
						type={enums.TRASH_TYPE_ALL}
						trash_type={enums.TRASH_TYPE_ALL}
						src={logo}
						alt=""
					/>
				);
			default:
				return null;
		}
	}

	function generateRain() {
		const id = "garbage_item";
		//const AppHeader = document.getElementById("App-header");
		const ttlJaune = variables.yElems.length;
		const ttlVert = variables.gElems.length;

		let ttl = ttlVert + ttlJaune + 2;
		if (getIsSuperMode()) {
			ttl = ttlVert + ttlJaune;
		}
		const garbage_type = Math.floor(Math.random() * ttl);
		const level = Math.floor(Math.random() * 10 + 5);
		const delay = Math.floor(Math.random() * 30 + 5);
		const properties: GarbageProperties = {
			id,
			garbage_type,
			level,
			delay,
			offset: 0,
			key: 0,
		};
		const elem = generateGarbage(garbage_type, properties);
		return { elem, properties };
	}

	const superMode = () => {
		//console.log("super mode begin count !!");
		clearInterval(superModeInterval);
		//const powerUp = document.getElementById("powerUp");
		const gameStartInterface = document.getElementById("gameStartInterface")!;
		const poubelle_item = document.getElementById("poubelle_item")!;
		const superModeBar = document.getElementById("superModeBar")!;
		const superModeText = document.getElementById("superModeText")!;
		poubelle_item.setAttribute("src", powerup);
		setIsSuperMode(true);
		superModeBar.classList.remove("active");
		superModeText.classList.remove("active");
		//powerUp.classList.remove("active");
		superModeBar.classList.add("active");
		superModeText.classList.add("active");
		//powerUp.classList.add("active");
		//gameStartInterface.classList.remove("supermode");
		//gameStartInterface.classList.add("supermode");

		superModeInterval = setInterval(() => {
			//console.log("super mode Off !!");
			gameStartInterface.classList.remove("supermode");
			//powerUp.classList.remove("active");
			superModeBar.classList.remove("active");
			superModeText.classList.remove("active");
			clearInterval(superModeInterval);
			setIsSuperMode(false);
			const typ = gameFunctions.getTrashType();
			//gameFunctions.setTrashType(typ);

			const GMode = getGameMode();
			poubelle_item.setAttribute(
				"src",
				gameFunctions.generateTrash(typ, GMode)
			);
			//poubelle_item.src =generateTrash(typ, GMode);
		}, variables.SUPERMODE_DURATION * 1000);
	};
	const setPointMarker = (isgood: boolean, top: number, left: number) => {
		if (isGarbageActive) {
			const pointContainer = document.getElementById("pointContainer")!;
			pointContainer.style.transition = "top .1s ease 0s";
			pointContainer.style.top = top + "px";
			//pointContainer.style.left = left + 40 + "px";
			setHasGoodPoint(true);
			setIsGoodPoint(isgood);
		}
	};
	const tryHitTest = () => {
		if (hasGarbage && !hasGoodPoint) {
			//const STAGE_HEIGHT =getNbLife() + 10;
			const trash = document.getElementById("poubelle")!;

			const powerUp = document.getElementById("powerUp")!;
			if (trash) {
				//console.log("tryHitTest");
				if (getNbLife() > 0 && powerUp) {
					//const tmpTrashleft = parseInt(trash.getBoundingClientRect().left);
					//const tmpTrashwidth = parseInt(trash.getBoundingClientRect().width);
					//const tmpTrashtop = parseInt(trash.getBoundingClientRect().top);
					//const tmpTrashheight = parseInt(trash.getBoundingClientRect().height);

					//powerUp.style.top = tmpTrashtop + tmpTrashheight / 2 - 250 / 2 + "px";
					//powerUp.style.left =tmpTrashleft + tmpTrashwidth / 2 - 250 / 2 + "px";

					hitTest();
				} else {
					//setIsGameOver(true);
				}
			}
		}
	};

	const hitTest = () => {
		//return true;
		const trashZoneContainer = document.getElementById("trashZoneContainer")!;
		const trash = document.getElementById("poubelle")!;
		const garbage = document.getElementById("garbage_item")!;

		if (trash && garbage) {
			//console.log("hitTest");
			//isWorking = true;
			//let nbLife =getNbLife();
			const superModeActived = getIsSuperMode();

			const gType = getGarbageType();

			if (
				garbage.getBoundingClientRect().left >=
					trash.getBoundingClientRect().left - 20 &&
				garbage.getBoundingClientRect().left +
					garbage.getBoundingClientRect().width <=
					trash.getBoundingClientRect().left +
						trash.getBoundingClientRect().width +
						20 &&
				garbage.getBoundingClientRect().top +
					garbage.getBoundingClientRect().height >=
					trashZoneContainer.getBoundingClientRect().top &&
				garbage.getBoundingClientRect().top +
					garbage.getBoundingClientRect().height <=
					trashZoneContainer.getBoundingClientRect().top +
						trashZoneContainer.getBoundingClientRect().height
			) {
				//"hit !!! :", gType);

				if (gType === enums.TRASH_TYPE_ALL) {
					if (!superModeActived) superMode();

					//console.log("SUPER MOOOOOOOODE !! scroe ++++ ");

					props.addScore(enums.SCORE_TYPE_SUPERMODE);
					//this.setState({ score: score });
				} else if (
					gType === gameFunctions.getTrashType() ||
					superModeActived
					//||					(getIsSuperMode() && gType != "all")
				) {
					//console.log("Good !! scroe ++ ");

					props.addScore(enums.SCORE_TYPE_SUCCESS);
					if (!hasGoodPoint) {
						setPointMarker(
							true,
							garbage.offsetTop,
							garbage.parentElement!.offsetLeft
						);
					}
				} else if (!superModeActived) {
					//nbLife--;

					if (!garbage.classList.contains("disabled")) {
						//
					}
					if (!hasGoodPoint) {
						props.addLife(-1);
						setPointMarker(
							false,
							garbage.offsetTop,
							garbage.parentElement!.offsetLeft
						);
					}
				}
				destroyGarbage(garbage);
				setIsGarbageActive(false);
			} else if (
				garbage.getBoundingClientRect().top +
					garbage.getBoundingClientRect().height >=
				trashZoneContainer.getBoundingClientRect().top +
					garbage.getBoundingClientRect().height +
					20
			) {
				//console.log("no-hit");
				//if (!superModeActived)
				if (gType !== enums.TRASH_TYPE_ALL) {
					if (!garbage.classList.contains("disabled")) {
						//
					}
					if (!hasGoodPoint) {
						props.addLife(-1);
						setPointMarker(
							false,
							garbage.offsetTop,
							garbage.parentElement!.offsetLeft
						);
					}
				} else {
					props.addLife(0);
				}
				setIsGarbageActive(false);
			}
			garbage.classList.add("disabled");
		}
	};

	useInterval(() => {
		if (!isInitialized) {
			//initGame();
			isInitialized = true;
		}
		const gSt = getGameStatus();
		if (gSt !== enums.GAMESTATUS_OVER) {
			moveGarbage();
			tryHitTest();
		}
		//console.log("tic");
	}, refreshInterval);
	/**/
	useEffect(() => {
		initGame();
		// initGame();
	}, []);

	return (
		<>
			<StyledPlayableZone className="playableZone">
				<StyledHeader
					id="App-header"
					className={
						//"App-header " +

						getGameStatus() === enums.GAMESTATUS_OVER
							? "gameOver"
							: " life_" + nbLife + " "
					}
					data-nbrows={STAGE_HEIGHT}
				>
					<StyledGameZone
						id="game_zone"
						className=""
						data-nbRows={STAGE_HEIGHT}
						data-nbColumns={STAGE_WIDTH}
					>
						{garbage}
					</StyledGameZone>
				</StyledHeader>
				<StyledGoodPoint
					id="pointContainer"
					className={
						"pointContainer " +
						(hasGoodPoint ? " active " : "") +
						(isGoodPoint ? " good_point_actived " : " bad_point_actived ")
					}
				>
					<StyledGoodPointImg
						className="good_point"
						src={goodImg}
						alt=""
					/>
					<StyledGoodPointImg
						className="bad_point"
						src={badImg}
						alt=""
					/>
				</StyledGoodPoint>

				<StyledFooter
					id="footer"
					className="App-footer "
					data-nbLife={nbLife}
					data-totalLife={parseInt(variables.INIT_NBLIFE.toString())}
				>
					<div className="GOPanel ">
						<img
							src={gameOver}
							alt=""
						/>
					</div>
					<img
						src={props.floor}
						alt=""
					/>
				</StyledFooter>
			</StyledPlayableZone>
		</>
	);
}

export default Stage;
