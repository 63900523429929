import styled from "styled-components";
//import { GameVars } from "vars/types";

export const StyledFooter = styled.div`
	/* position: absolute;
	transition: top 0.1s ease 0s;
	top: calc(${(props) =>
				parseInt(
					(
						(parseInt(props["data-nbLife"]) /
							(parseInt(props["data-totalLife"]) + 1)) *
						100
					).toString()
				)}% - 6.15%) !important; 
	width: 100%;*/
`;
