/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CiteoUser } from "vars/types";
import { create } from "zustand";
import axios from "axios";
import * as enums from "vars/enums";
import * as variables from "vars/variables";
import * as sharedMethods from "./sharedMethods";

let prox = "";
prox = "";
//prox = "https://cors.bridged.cc/";
//prox ="https://cors-anywhere.herokuapp.com/";
const scoreListString = "ScoreList_" + window.location.host;
const statApi = process.env.VITE_STAT_API_URL;

let _workData = "";

interface ModalState {
	updatedAt: number;
	totalLife: number;
	validated: boolean;
	orientation: boolean;
	etape2_validee: boolean;
	configPage: number;

	setTtotalLife: (totalLife: number) => void;
	setValidated: (validated: boolean) => void;
	setOrientation: (orientation: boolean) => void;
	setEtape2_validee: (etape2_validee: boolean) => void;
	setConfigPage: (configPage: number) => void;

	getStatData(CAMPAGNE_CITEO_CHARITY: number, data: any): unknown;
	getIpdetails(data: any, isCalledWithGET: boolean): unknown;
	workData(cachedData: any, isCalledWithGET: boolean): unknown;

	getAllDetails: () => string;

	//  SETTERS
	setVersion: () => void;
	setScore: (score: number) => void;
	setIsOnline: (isOnline: number) => void;
	setCagnotte: (cagnotte: number) => void;
	setGameStatus: (gameStatus: number) => void;
	setNbLife: (nbLife: number) => void;
	setGameMode: (gameMode: number) => void;
	setNickName: (nickName: string) => void;
	setIsSuperMode: (value: boolean) => void;
	setSpeed: (value: number) => void;
	setGarbageType: (value: number) => void;
	setInstructionPage: (value: number) => void;
	setUserScore: (value: number) => void;
	setSelectedUser: (value: number) => void;
	setMobileType: (value: number) => void;
	setScoreList: (list: CiteoUser[]) => void;
	//  GETTERS

	getRegion: () => string;
	getScore: () => number;
	getIsOnline: () => number;
	getCagnotte: () => number;
	getGameStatus: () => number;
	getNbLife: () => number;
	getGameMode: () => number;
	getNickName: () => number;
	getIsSuperMode: () => number;
	getSpeed: () => number;
	getIsGameOver: () => boolean;
	getGarbageType: () => number;
	getInstructionPage: () => number;
	getUserScore: () => number;
	getSelectedUser: () => number;
	getMobileType: () => number;
	getScoreList: () => any[];
	getData: (isGET?: boolean) => void;
	getCurrCagnotte: () => void;
}

export const useGameInterfaceStore = create<ModalState>()((set, get) => ({
	updatedAt: 0,
	totalLife: variables.INIT_NBLIFE,
	validated: false,
	orientation: true,
	etape2_validee: false,
	configPage: 1,

	setTtotalLife: (totalLife: number) => {
		set({ totalLife, updatedAt: get().updatedAt++ });
	},
	setValidated: (validated: boolean) => {
		set({ validated, updatedAt: get().updatedAt++ });
	},
	setOrientation: (orientation: boolean) => {
		set({ orientation, updatedAt: get().updatedAt++ });
	},
	setEtape2_validee: (etape2_validee: boolean) => {
		set({ etape2_validee, updatedAt: get().updatedAt++ });
	},
	setConfigPage: (configPage: number) => {
		set({ configPage, updatedAt: get().updatedAt++ });
	},

	getAllDetails: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		return JSON.stringify({ ...jetriebien });
	},

	setVersion: () => {
		const jetriebien = sharedMethods.getGoodStrorageContent();
		sharedMethods.setStorage({
			...jetriebien,
			...sharedMethods.newGame,
			instructionPage: enums.INTRUCTION_PAGE_INTRO_1,
		});
		//set({updatedAt:get().updatedAt++})
	},

	setScore: (score: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.score !== score) {
			//console.log(`store.setScore:${score}`);
			sharedMethods.setStorage({ ...jetriebien, score: score });
			//set({updatedAt:get().updatedAt++})
		}
	},
	savePlaceData: (data: any) => {
		//console.log(`store.savePlaceData`);
		const jetriebien = sharedMethods.getJeTrieBien();
		if (data) {
			const ecrpt = data;
			sharedMethods.setStorage({
				...jetriebien,
				placeData: {
					debut: new Date(),
					value: ecrpt,
				},
			});
		} else {
			sharedMethods.setStorage({
				...jetriebien,
				placeData: null,
			});
		}
		//set({updatedAt:get().updatedAt++})
	},

	getRegion: () => {
		//console.log(`store.getRegion`);
		const jetriebien = sharedMethods.getJeTrieBien();
		if (!jetriebien.placeData) {
			//get().getData();
			return null;
		}

		const obj = jetriebien.placeData.value;
		return obj.countryCode;
	},

	setIsOnline: (isOnline: number) => {
		//console.log(`store.setIsOnline:${isOnline}`);
		const jetriebien = sharedMethods.getJeTrieBien();
		sharedMethods.setStorage({ ...jetriebien, isOnline: isOnline });
		//set({updatedAt:get().updatedAt++})
	},
	setCagnotte: (cagnotte: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.cagnotte !== cagnotte) {
			//console.log(`store.setCagnotte:${cagnotte}`);
			sharedMethods.setStorage({ ...jetriebien, cagnotte: cagnotte });
			get().setIsOnline(1);
		}
		//set({updatedAt:get().updatedAt++})
	},
	setGameStatus: (gameStatus: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.gameStatus !== gameStatus) {
			//console.log(`store.setGameStatus:${gameStatus}`);
			sharedMethods.setStorage({ ...jetriebien, gameStatus: gameStatus });
			if (gameStatus === enums.GAMESTATUS_SPLASH) {
				get().setNbLife(variables.INIT_NBLIFE);
			}
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setNbLife: (nbLife: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.nbLife !== nbLife) {
			//console.log(`store.setNbLife:${nbLife}`);
			sharedMethods.setStorage({
				...jetriebien,
				nbLife: nbLife,
				isGameOver: nbLife <= 0,
			});
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setGameMode: (gameMode: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.gameMode !== gameMode) {
			//console.log(`store.setGameMode:${gameMode}`);
			sharedMethods.setStorage({ ...jetriebien, gameMode: gameMode });
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setNickName: (nickName: string) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.nickName !== nickName) {
			//console.log(`store.setNickName:${nickName}`);
			sharedMethods.setStorage({ ...jetriebien, nickName: nickName });
			//set({updatedAt:get().updatedAt++})
		}
	},
	setIsSuperMode: (value: boolean) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.superMode !== value) {
			//console.log(`store.setIsSuperMode:${value}`);
			sharedMethods.setStorage({ ...jetriebien, superMode: value });
			//set({updatedAt:get().updatedAt++})
		}
	},
	setSpeed: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.speed !== value) {
			//console.log(`store.setSpeed:${value}`);
			sharedMethods.setStorage({ ...jetriebien, speed: value });
			//set({updatedAt:get().updatedAt++})
		}
	},
	setGarbageType: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.garbageType !== value) {
			//console.log(`store.setGarbageType:${value}`);
			sharedMethods.setStorage({ ...jetriebien, garbageType: value });
			//set({updatedAt:get().updatedAt++})
		}
	},
	setInstructionPage: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.instructionPage !== value) {
			//console.log(`store.setInstructionPage:${value}`);
			sharedMethods.setStorage({ ...jetriebien, instructionPage: value });
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setUserScore: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.userScore !== value) {
			//console.log(`store.setUserScore:${value}`);
			sharedMethods.setStorage({ ...jetriebien, userScore: value });
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setSelectedUser: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.selectedUser !== value) {
			//console.log(`store.setSelectedUser:${value}`);
			sharedMethods.setStorage({ ...jetriebien, selectedUser: value });
			set({ updatedAt: get().updatedAt++ });
		}
	},
	setMobileType: (value: number) => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (jetriebien.mobileType !== value) {
			//console.log(`store.setMobileType:${value}`);
			sharedMethods.setStorage({ ...jetriebien, mobileType: value });
			//set({updatedAt:get().updatedAt++})
		}
	},

	setScoreList: (list: CiteoUser[]) => {
		//console.log(`store.setScoreList:${list}`);

		localStorage.setItem(scoreListString, JSON.stringify(list));
		//set({updatedAt:get().updatedAt++})
	},

	getScore: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getScore=>${jetriebien.score}`);
		if (jetriebien.score) {
			return parseInt(jetriebien.score);
		} else {
			//get().setScore(0);
			return 0;
		}
	},
	getIsOnline: () => {
		return 1;
		try {
			const promesseA = new Promise(() => get().getData());
			promesseA.then((val) => {
				
				get().setIsOnline(1);
			});
		} catch (error) {
			
			get().setIsOnline(0);
		}

		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getIsOnline=>${jetriebien.isOnline}`);
		if (jetriebien.isOnline) {
			return parseInt(jetriebien.isOnline);
		} else {
			//get().setIsOnline(0);
			return 0;
		}
	},
	getCagnotte: () => {
		get().getCurrCagnotte();
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getCagnotte=>${jetriebien.cagnotte}`);
		if (jetriebien.cagnotte) {
			return parseInt(jetriebien.cagnotte);
		} else {
			return 0;
		}
	},
	getGameStatus: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getGameStatus=>${jetriebien.gameStatus}`);
		return parseInt(jetriebien.gameStatus);
	},
	getNbLife: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getNbLife=>${jetriebien.nbLife}`);
		return parseInt(jetriebien.nbLife);
	},
	getGameMode: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getGameMode=>${jetriebien.gameMode??enums.GAME_MODE_HARD}`);
		return parseInt((jetriebien.gameMode ?? enums.GAME_MODE_HARD).toString());
	},
	getNickName: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getNickName=>${jetriebien.nickName}`);
		return jetriebien.nickName;
	},
	getIsSuperMode: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getIsSuperMode=>${jetriebien.superMode}`);
		return jetriebien.superMode;
	},
	getSpeed: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getSpeed=>${jetriebien.speed}`);
		return jetriebien.speed;
	},
	getIsGameOver: () => {
		const nbLife = get().getNbLife();
		//console.log(`store.getIsGameOver=>${nbLife > 0}`);
		return nbLife > 0;
	},
	getGarbageType: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getGarbageType=>${jetriebien.garbageType}`);
		return parseInt(jetriebien.garbageType);
	},
	getInstructionPage: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		// console.log(`store.getInstructionPage=>${jetriebien.instructionPage
		// 	? jetriebien.instructionPage
		// 	: enums.INTRUCTION_PAGE_INTRO_1}`);
		return jetriebien.instructionPage
			? jetriebien.instructionPage
			: enums.INTRUCTION_PAGE_INTRO_1;
	},
	getUserScore: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		if (!jetriebien.userScore) jetriebien.userScore = 0;
		//console.log(`store.getUserScore=>${jetriebien.userScore}`);
		return parseInt(jetriebien.userScore);
	},

	getSelectedUser: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getSelectedUser=>${jetriebien.selectedUser}`);
		return parseInt(jetriebien.selectedUser);
	},

	getMobileType: () => {
		const jetriebien = sharedMethods.getJeTrieBien();
		//console.log(`store.getMobileType=>${jetriebien.mobileType}`);
		return parseInt(jetriebien.mobileType);
	},

	getScoreList: () => {
		const ScoreList = localStorage.getItem(scoreListString);
		if (ScoreList) {
			//console.log(`store.getScoreList=>${JSON.parse(ScoreList)}`);
			return JSON.parse(ScoreList);
		}
		return [];
	},

	getData: (isGET?: boolean) => {
		//console.log(`store.getData`);
		const isCalledWithGET = typeof isGET === "boolean" ? isGET : false;
		const cachedData = sharedMethods.loadPlaceData();

		if (!cachedData || !cachedData.dmaCode) {
			try {
				const url = prox + process.env.VITE_IP_API_URL;
				return axios.get(url).then(function (response: any) {
					const data = response.data
						.trim()
						.split("\n")
						.reduce(function (obj: any, pair: string) {
							const arr_pair = pair.split("=");
							return (obj[arr_pair[0]] = arr_pair[1]), obj;
						}, {});
					get().getIpdetails(data, isCalledWithGET);
				});
				// .catch((err: any) => {
				// 	//todo catch error and report
				// });
			} catch (error: any) {
				//todo catch error and report
			}
		}

		const wd = get().workData(cachedData, isCalledWithGET);
		return wd;
	},

	getIpdetails: (ipShortDetail: any, isGET: boolean) => {
		//console.log(`store.getIpdetails`);
		const isCalledWithGET = typeof isGET === "boolean" ? isGET : false;
		if (ipShortDetail) {
			//ipsnippet = ipShortDetail;
			if (disableRecheck) {
				//return
				get().workData(null, isCalledWithGET);
			} else {
				const ipv4 = ipShortDetail.IPv4 ? ipShortDetail.IPv4 : ipShortDetail.ip;
				const ipDetailApiUri =
					//prox +
					process.env.VITE_IP_DETAIL_API_URL +
					ipv4 +
					"?token=" +
					process.env.VITE_IP_API_TOKEN;
				//return
				axios
					.get(
						ipDetailApiUri /*, {
						headers: fetchHeaders,
					}*/
					)
					.then((response: any) => {
						const resp = {...response.data, ...ipShortDetail } ; //.json();
						
						//resp.then((data: any) => {
						//get().workData(data, isCalledWithGET);
						//	setIpDetail(db_entry);
						//});
						get().workData(resp, isCalledWithGET);
					});
				// .catch((err: any) => {
				// 	//todo catch error and report
				// });
			}
		}
	},

	workData: (data: any, isCalledWithGET: boolean) => {
		//console.log(`store.workData`);

		const cachedData = sharedMethods.loadPlaceData();

		if (!cachedData || !cachedData.dmaCode) {
			sharedMethods.savePlaceData(
				sharedMethods.getCampagneValuesFromUrl(0, null, data)
			);
		}

		if (isCalledWithGET) {
			get().getStatData(enums.CAMPAGNE_CITEO_CHARITY, data);
		} else {
			const parsedData = sharedMethods.getCampagneValuesFromUrl(
				enums.CAMPAGNE_CITEO_STAT,
				"",
				data
			);

			const uScore = get().getUserScore();
			const currScore = get().getScore();
			
			if (parsedData.href !== _workData) {
				_workData = parsedData.href;
				
				sharedMethods.postStatData(enums.CAMPAGNE_CITEO_STAT, {
					...data,
					score: currScore,
					totalScore: uScore,
				});
			}
			if (currScore >= 10000 && parsedData.href.includes("#GameOver")) {
				
				sharedMethods.postStatData(enums.CAMPAGNE_CITEO_CHARITY, {
					...data,
					score: currScore,
					totalScore: uScore,
				});
				//get().setUserScore(0);
			}
		}
	},
	getStatData: (campagne: number, data: any) => {
		//console.log(`store.getStatData`);
		const queryParams = new URLSearchParams(window.location.search);
		const parsedData = sharedMethods.getCampagneValuesFromUrl(
			campagne,
			queryParams,
			data
		);

		const ecr = sharedMethods.encrypt(parsedData) ?? "";
		const db_entry = encodeURIComponent(ecr);

		const test = sharedMethods.decrypt(decodeURIComponent(db_entry));

		axios
			.get(prox + statApi + "?total=1&data=" + db_entry, {
				headers: {
					/*...fetchHeaders,*/
					"content-type": "application/json",
					Authorization: `Bearer ${process.env.VITE_AUTH_TO_STATS}`,
				},
			})
			.then((response: any) => {
				get().setCagnotte(response.data * variables.WINNER_PRICE);
			});
		// .catch((err: any) => {
		// 	//todo catch error and report
		// });
	},
	getCurrCagnotte: () => {
		get().getData(true);
	},
}));
const disableRecheck = false;
